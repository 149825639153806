var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-title',[_vm._v(" Pelatihan "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari Nama","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/kegiatan-add"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""}},[_vm._v(" Tambah ")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Apa anda akan menghapus data ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Tidak")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Ya")]),_c('v-spacer')],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list_data,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.tanggal_awal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCurrentDate(item.tanggal_awal))+" ")]}},{key:"item.tanggal_akhir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCurrentDate(item.tanggal_akhir))+" ")]}},{key:"item.harga",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Intl.NumberFormat("id-ID").format(item.harga))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"medium":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }