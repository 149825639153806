import http from "../http-common";
/* eslint-disable */
class NakesService {
  anumertaList(page, itemPerPage, keyword, propinsiId, noNpa) {
    return http.get(
      "/anumerta/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId +
        "&no_npa=" +
        noNpa,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesList(page, itemPerPage, keyword, propinsiId, noNpa, tahun, status) {
    return http.get(
      "/nakes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId +
        "&no_npa=" +
        noNpa +
        "&tahun=" +
        tahun +
        "&status=" +
        status,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesListPengurus(page, itemPerPage, keyword, propinsiId) {
    return http.get(
      "/nakes/list/pengurus?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesListPengurusCabang(page, itemPerPage, keyword, propinsiId) {
    return http.get(
      "/nakes/list/cabang?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesListPengurusKati(page, itemPerPage, keyword, propinsiId) {
    return http.get(
      "/nakes/list/kati?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  nakesByCabangList(page, itemPerPage, keyword, propinsiId) {
    return http.get(
      "/nakes-cabang/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  calonNakesList(page, itemPerPage, keyword, propinsiId) {
    return http.get(
      "/calon-nakes/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&cabang_id=" +
        propinsiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getUser(id) {
    return http.get(`/nakes/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  profesiList() {
    return http.get("/profesi/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  jenisKelaminList() {
    return http.get("/jeniskelamin/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  pendidikanList() {
    return http.get("/pendidikan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  statusPernikahanList() {
    return http.get("/statuspernikahan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  statusPekerjaanList() {
    return http.get("/statuspekerjaan/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  communityList(profesiId) {
    return http.get("/community/list?profesi_id=" + profesiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  communityListAll() {
    return http.get("/community/list/all", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  masterBankList() {
    return http.get("/ipaymu/bank/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  propinsiList() {
    return http.get("/propinsi/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kabupatenList(propinsiId) {
    return http.get("/kabupaten/list?propinsi_id=" + propinsiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kecamatanList(propinsiId) {
    return http.get("/kecamatan/list?kabupaten_id=" + propinsiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kelurahanList(propinsiId) {
    return http.get("/kelurahan/list?kecamatan_id=" + propinsiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  propinsiListIpaymu() {
    return http.get("/ipaymu/propinsi/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kabupatenListIpaymu(propinsiId) {
    return http.get("/ipaymu/kabupaten/list?propinsi_id=" + propinsiId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kecamatanListIpaymu(kabupatenId) {
    return http.get("/ipaymu/kecamatan/list?kabupaten_id=" + kabupatenId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  kelurahanListIpaymu(kecamatanId) {
    return http.get("/ipaymu/kelurahan/list?kecamatan_id=" + kecamatanId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, photo, foto_ktp, foto_ijazah, foto_str, foto_stm) {
    var bodyFormData = new FormData();
    if (data.gelar_depan) bodyFormData.append("gelar_depan", data.gelar_depan);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.gelar_belakang)
      bodyFormData.append("gelar_belakang", data.gelar_belakang);
    if (data.phone) bodyFormData.append("phone", data.phone);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.str) bodyFormData.append("str", data.str);
    if (data.no_anggota) bodyFormData.append("no_anggota", data.no_anggota);
    if (data.no_ktp) bodyFormData.append("no_ktp", data.no_ktp);
    if (data.tempat_lahir)
      bodyFormData.append("tempat_lahir", data.tempat_lahir);

    if (data.tanggal_lahir)
      bodyFormData.append("tanggal_lahir", data.tanggal_lahir);

    if (data.masa_berlaku)
      bodyFormData.append("masa_berlaku", data.masa_berlaku);

    if (data.address) bodyFormData.append("address", data.address);

    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      bodyFormData.append("profesi_id", data.selectedProfesi.code);
    }

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("jenis_kelamin_id", data.selectedJenisKelamin.code);
    }

    if (data.selectedStatusPegawai && data.selectedStatusPegawai != undefined) {
      bodyFormData.append(
        "status_pekerjaan_id",
        data.selectedStatusPegawai.code
      );
    }

    if (
      data.selectedGolonganPegawai &&
      data.selectedGolonganPegawai != undefined
    ) {
      bodyFormData.append(
        "golongan_pekerjaan_id",
        data.selectedGolonganPegawai.code
      );
    }

    if (data.selectedAgama && data.selectedAgama != undefined) {
      bodyFormData.append("agama_id", data.selectedAgama.code);
    }

    if (data.selectedCommunity && data.selectedCommunity != undefined) {
      bodyFormData.append("community_id", data.selectedCommunity.id);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    if (data.selectedCabang && data.selectedCabang != undefined) {
      bodyFormData.append("cabang_id", data.selectedCabang.id);
    }

    if (data.selectedJenisAnggota && data.selectedJenisAnggota != undefined) {
      bodyFormData.append("jenis_anggota", data.selectedJenisAnggota.code);
    }

    if (data.no_npa) bodyFormData.append("no_npa", data.no_npa);
    if (data.npa_idi) bodyFormData.append("npa_idi", data.npa_idi);
    if (data.perguruan_tinggi)
      bodyFormData.append("perguruan_tinggi", data.perguruan_tinggi);

    if (data.perguruan_tinggi_spesialis)
      bodyFormData.append(
        "perguruan_tinggi_spesialis",
        data.perguruan_tinggi_spesialis
      );

    if (data.tahun_lulus) bodyFormData.append("tahun_lulus", data.tahun_lulus);

    if (data.tahun_masuk_spesialis)
      bodyFormData.append("tahun_masuk_spesialis", data.tahun_masuk_spesialis);

    if (data.tahun_lulus_spesialis)
      bodyFormData.append("tahun_lulus_spesialis", data.tahun_lulus_spesialis);

    if (photo != null) bodyFormData.append("photo", photo);
    if (foto_ktp != null) bodyFormData.append("foto_ktp", foto_ktp);
    if (foto_ijazah != null) bodyFormData.append("foto_ijazah", foto_ijazah);
    if (foto_str != null) bodyFormData.append("foto_str", foto_str);
    if (foto_stm != null) bodyFormData.append("foto_stm", foto_stm);

    return http.post("/calon-nakes", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(
    id,
    data,
    photo,
    foto_ktp,
    foto_ijazah,
    foto_str,
    foto_stm,
    file_pdf_ijazah
  ) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.gelar_depan) bodyFormData.append("gelar_depan", data.gelar_depan);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.gelar_belakang)
      bodyFormData.append("gelar_belakang", data.gelar_belakang);
    if (data.phone) bodyFormData.append("phone", data.phone);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.str) bodyFormData.append("str", data.str);
    if (data.no_anggota) bodyFormData.append("no_anggota", data.no_anggota);
    if (data.no_ktp) bodyFormData.append("no_ktp", data.no_ktp);
    if (data.tempat_lahir)
      bodyFormData.append("tempat_lahir", data.tempat_lahir);

    if (data.tanggal_lahir)
      bodyFormData.append("tanggal_lahir", data.tanggal_lahir);

    if (data.masa_berlaku)
      bodyFormData.append("masa_berlaku", data.masa_berlaku);

    if (data.address) bodyFormData.append("address", data.address);

    if (data.selectedProfesi && data.selectedProfesi != undefined) {
      bodyFormData.append("profesi_id", data.selectedProfesi.code);
    }

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("jenis_kelamin_id", data.selectedJenisKelamin.code);
    }

    if (data.selectedStatusPegawai && data.selectedStatusPegawai != undefined) {
      bodyFormData.append(
        "status_pekerjaan_id",
        data.selectedStatusPegawai.code
      );
    }

    if (
      data.selectedGolonganPegawai &&
      data.selectedGolonganPegawai != undefined
    ) {
      bodyFormData.append(
        "golongan_pekerjaan_id",
        data.selectedGolonganPegawai.code
      );
    }

    if (data.selectedAgama && data.selectedAgama != undefined) {
      bodyFormData.append("agama_id", data.selectedAgama.code);
    }

    if (data.selectedCommunity && data.selectedCommunity != undefined) {
      bodyFormData.append("community_id", data.selectedCommunity.id);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("propinsi_id", data.selectedPropinsi.id);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("kabupaten_id", data.selectedKabupaten.id);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("kecamatan_id", data.selectedKecamatan.id);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("kelurahan_id", data.selectedKelurahan.id);
    }

    if (data.selectedCabang && data.selectedCabang != undefined) {
      bodyFormData.append("cabang_id", data.selectedCabang.id);
    }

    if (data.selectedJenisAnggota && data.selectedJenisAnggota != undefined) {
      bodyFormData.append("jenis_anggota", data.selectedJenisAnggota.code);
    }

    if (data.no_npa) bodyFormData.append("no_npa", data.no_npa);
    if (data.npa_idi) bodyFormData.append("npa_idi", data.npa_idi);
    if (data.perguruan_tinggi)
      bodyFormData.append("perguruan_tinggi", data.perguruan_tinggi);

    if (data.perguruan_tinggi_spesialis)
      bodyFormData.append(
        "perguruan_tinggi_spesialis",
        data.perguruan_tinggi_spesialis
      );

    if (data.tahun_lulus) bodyFormData.append("tahun_lulus", data.tahun_lulus);

    if (data.tahun_masuk_spesialis)
      bodyFormData.append("tahun_masuk_spesialis", data.tahun_masuk_spesialis);

    if (data.tahun_lulus_spesialis)
      bodyFormData.append("tahun_lulus_spesialis", data.tahun_lulus_spesialis);

    if (photo != null) bodyFormData.append("photo", photo);
    if (foto_ktp != null) bodyFormData.append("foto_ktp", foto_ktp);
    if (foto_ijazah != null) bodyFormData.append("foto_ijazah", foto_ijazah);
    if (foto_str != null) bodyFormData.append("foto_str", foto_str);
    if (foto_stm != null) bodyFormData.append("foto_stm", foto_stm);
    if (file_pdf_ijazah != null)
      bodyFormData.append("file_ijazah", file_pdf_ijazah);

    return http.put(`/nakes/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  approve(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/approve/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  resetPassword(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/reset-password/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  jadikanAnggota(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/jadikan-anggota/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  jadikanAnumerta(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/jadikan-anumerta/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  approveAnumerta(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/approve-anumerta/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  rejectAnumerta(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/reject-anumerta/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  calonDelete(id) {
    return http.delete(`/calon-nakes/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/nakes/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  approveDelete(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/approve/delete/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  rejectDelete(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/nakes/reject/delete/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  nakesListPengurusPusatAdd() {
    return http.get("/nakes/list/pengurus-pusat-add", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  nakesListPengurusPusatEdit(id) {
    return http.get("/nakes/list/pengurus-pusat-edit?id=" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  nakesListPengurusWilayahAdd() {
    return http.get("/nakes/list/pengurus-wilayah-add", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  nakesListPengurusWilayahEdit(id) {
    return http.get("/nakes/list/pengurus-wilayah-edit?id=" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new NakesService();
