import http from "../http-common";
/* eslint-disable */
class PesertaPibService {
  pesertaPibList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib-user/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.no_npa) bodyFormData.append("no_npa", data.no_npa);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.whatsapp) bodyFormData.append("whatsapp", data.whatsapp);
    if (data.email) bodyFormData.append("email", data.email);
    if (data.institusi) bodyFormData.append("institusi", data.institusi);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("jenis_anggota", data.selectedKategori.code);
    }

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/pib-user/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new PesertaPibService();
