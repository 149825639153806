import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Nakes from "../views/Nakes.vue";
import Anumerta from "../views/Anumerta.vue";
import Mutasi from "../views/Mutasi.vue";
import Iuran from "../views/Iuran.vue";
import Borang from "../views/Borang.vue";
import Absensi from "../views/Absensi.vue";
import Dashboard from "../views/Dashboard.vue";
import Kegiatan from "../views/Kegiatan.vue";
import KegiatanAdd from "../views/KegiatanAdd.vue";
import KegiatanEdit from "../views/KegiatanEdit.vue";
import EventPerdatin from "../views/EventPerdatin.vue";
import EventPerdatinAdd from "../views/EventPerdatinAdd.vue";
import EventPerdatinEdit from "../views/EventPerdatinEdit.vue";
import Report from "../views/report/Report.vue";
import AgendaPelatihan from "../views/webcontent/AgendaPelatihan.vue";

import Event from "../views/Event.vue";
import EventAdd from "../views/EventAdd.vue";
import EventEdit from "../views/EventEdit.vue";

import NakesAdd from "../views/NakesAdd.vue";
import NakesEdit from "../views/NakesEdit.vue";
import User from "../views/User.vue";
import UserAdd from "../views/UserAdd.vue";
import UserEdit from "../views/UserEdit.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import PrivacyMobile from "../views/PrivacyMobile.vue";
import Login from "../views/Login.vue";
import Profesi from "../views/Profesi.vue";
import Faskes from "../views/Faskes.vue";
import FaskesNakes from "../views/FaskesNakes.vue";
import FaskesCategory from "../views/FaskesCategory.vue";
import Chats from "../views/Chats.vue";
import ChatsList from "../views/ChatsList.vue";
import TentangKami from "../views/TentangKami.vue";
import KontakKami from "../views/KontakKami.vue";
import SyaratKetentuan from "../views/SyaratKetentuan.vue";
import Privacy from "../views/Privacy.vue";
import Bantuan from "../views/Bantuan.vue";
import Layanan from "../views/Layanan.vue";
import Spesialisasi from "../views/Spesialisasi.vue";
import Wilayah from "../views/Wilayah.vue";
import Kabupaten from "../views/Kabupaten.vue";
import Pers from "../views/Pers.vue";
import PersAdd from "../views/PersAdd.vue";
import PersEdit from "../views/PersEdit.vue";
import Bank from "../views/Bank.vue";
import EvaluasiFasilitator from "../views/EvaluasiFasilitator.vue";

//web content
import BrosurList from "../views/webcontent/BrosurList.vue";
import JadwalPelatihanList from "../views/webcontent/JadwalPelatihanList.vue";
import Jabatan from "../views/master/Jabatan.vue";
import Direksi from "../views/webcontent/DireksiList.vue";
import KatalogBuku from "../views/webcontent/KatalogBuku.vue";
import KatalogBukuAdd from "../views/webcontent/KatalogBukuAdd.vue";
import KatalogBukuEdit from "../views/webcontent/KatalogBukuEdit.vue";
import Berita from "../views/webcontent/BeritaList.vue";
import Keseminatan from "../views/webcontent/Keseminatan.vue";
import KeseminatanAdd from "../views/webcontent/KeseminatanAdd.vue";
import KeseminatanEdit from "../views/webcontent/KeseminatanEdit.vue";
import BeritaAdd from "../views/webcontent/BeritaAdd.vue";
import BeritaEdit from "../views/webcontent/BeritaEdit.vue";
import Testimoni from "../views/webcontent/TestimoniList.vue";
import Fasilitas from "../views/webcontent/FasilitasList.vue";
import Fasilitator from "../views/webcontent/FasilitatorList.vue";
import StrukturOrganisasi from "../views/webcontent/StrukturOrganisasi.vue";
import VisiMisi from "../views/webcontent/VisiMisi.vue";
import TentangPerdatin from "../views/webcontent/TentangPerdatin.vue";
import Instagram from "../views/webcontent/Instagram.vue";
import Seminat from "../views/webcontent/Seminat.vue";
import AdRt from "../views/webcontent/AdRt.vue";
import Pengurus from "../views/webcontent/Pengurus.vue";
import PengurusCabang from "../views/webcontent/PengurusCabang.vue";
import PengurusKati from "../views/webcontent/PengurusKati.vue";
import Slider from "../views/webcontent/Slider.vue";

import AdminPib from "../views/pib/AdminPib.vue";

import Timeline from "../views/Timeline.vue";
import Komunitas from "../views/Komunitas.vue";

import PengurusPusat from "../views/PengurusPusat.vue";
import PengurusPusatAdd from "../views/PengurusPusatAdd.vue";
import PengurusPusatEdit from "../views/PengurusPusatEdit.vue";

import PengurusWilayah from "../views/PengurusWilayah.vue";
import PengurusWilayahAdd from "../views/PengurusWilayahAdd.vue";
import PengurusWilayahEdit from "../views/PengurusWilayahEdit.vue";

import CalonAnggota from "../views/CalonNakes.vue";
import Fasyankes from "../views/Fasyankes.vue";
import FasyankesAdd from "../views/FasyankesAdd.vue";
import FasyankesEdit from "../views/FasyankesEdit.vue";

import PesanKapusdokkes from "../views/webcontent/PesanKapusdokkes.vue";
import PesanVideo from "../views/webcontent/PesanVideo.vue";
import ProgramKerja from "../views/webcontent/ProgramKerja.vue";
import Kapusdokkes from "../views/webcontent/KapusdokkesList.vue";
import MitraPusdokkes from "../views/webcontent/MitraPusdokkes.vue";
import Galeri from "../views/webcontent/Galeri.vue";
import Pengaduan from "../views/webcontent/Pengaduan.vue";
import Advokasi from "../views/webcontent/Advokasi.vue";
import Kontak from "../views/webcontent/Kontak.vue";
import CabangPerdatin from "../views/webcontent/CabangPerdatin.vue";
import BankDataPenelitian from "../views/webcontent/BankDataPenelitian.vue";

//master
import JenisRS from "../views/JenisRS.vue";
import KelasRS from "../views/KelasRS.vue";
import Kepemilikan from "../views/Kepemilikan.vue";
import BidangPengurus from "../views/BidangPengurus.vue";
import BidangPengurusEdit from "../views/BidangPengurusEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    props: true,
    component: Login,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    props: true,
    component: PrivacyPolicy,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/privacymobile",
    name: "PrivacyMobile",
    props: true,
    component: PrivacyMobile,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/pers",
    name: "Pers",
    props: true,
    component: Pers,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pers-add",
    name: "PersAdd",
    props: true,
    component: PersAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pers-edit/:id",
    name: "PersEdit",
    props: true,
    component: PersEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/anumerta",
    name: "Anumerta",
    props: true,
    component: Anumerta,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/nakes",
    name: "Nakes",
    props: true,
    component: Nakes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/nakes-add",
    name: "NakesAdd",
    props: true,
    component: NakesAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/nakes-edit/:id",
    name: "NakesEdit",
    props: true,
    component: NakesEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/mutasi",
    name: "Mutasi",
    props: true,
    component: Mutasi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/iuran",
    name: "Iuran",
    props: true,
    component: Iuran,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/borang",
    name: "Borang",
    props: true,
    component: Borang,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/absensi",
    name: "Absensi",
    props: true,
    component: Absensi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    props: true,
    component: Dashboard,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kegiatan",
    name: "Kegiatan",
    props: true,
    component: Kegiatan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kegiatan-add",
    name: "KegiatanAdd",
    props: true,
    component: KegiatanAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kegiatan-edit/:id",
    name: "KegiatanEdit",
    props: true,
    component: KegiatanEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/event-edit/:id",
    name: "EventEdit",
    props: true,
    component: EventEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/event",
    name: "Event",
    props: true,
    component: Event,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/event-add",
    name: "EventAdd",
    props: true,
    component: EventAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/eventperdatin",
    name: "EventPerdatin",
    props: true,
    component: EventPerdatin,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/eventperdatin-add",
    name: "EventPerdatinAdd",
    props: true,
    component: EventPerdatinAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/eventperdatin-edit/:id",
    name: "EventPerdatinEdit",
    props: true,
    component: EventPerdatinEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/admin-pib",
    name: "AdminPib",
    props: true,
    component: AdminPib,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/report/kegiatan/:kegiatanId/user/:id",
    name: "report",
    props: true,
    component: Report,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/user",
    name: "User",
    props: true,
    component: User,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/user-add",
    name: "UserAdd",
    props: true,
    component: UserAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/user-edit/:id",
    name: "UserEdit",
    props: true,
    component: UserEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/profesi",
    name: "Profesi",
    props: true,
    component: Profesi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/wilayah",
    name: "Wilayah",
    props: true,
    component: Wilayah,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kabupaten/:id",
    name: "Kabupaten",
    props: true,
    component: Kabupaten,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/spesialisasi",
    name: "Spesialisasi",
    props: true,
    component: Spesialisasi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/bank",
    name: "Bank",
    props: true,
    component: Bank,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/evaluasi-fasilitator",
    name: "Evaluasi Fasilitator",
    props: true,
    component: EvaluasiFasilitator,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/layanan",
    name: "Layanan",
    props: true,
    component: Layanan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/faskes",
    name: "Faskes",
    props: true,
    component: Faskes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/faskes/nakes/:id",
    name: "FaskesNakes",
    props: true,
    component: FaskesNakes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/faskes-category",
    name: "FaskesCategory",
    props: true,
    component: FaskesCategory,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/tentang-kami",
    name: "TentangKami",
    props: true,
    component: TentangKami,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kontak-kami",
    name: "KontakKami",
    props: true,
    component: KontakKami,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/cabangperdatin",
    name: "CabangPerdatin",
    props: true,
    component: CabangPerdatin,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/bankdatapenelitian",
    name: "BankDataPenelitian",
    props: true,
    component: BankDataPenelitian,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/syarat-ketentuan",
    name: "SyaratKetentuan",
    props: true,
    component: SyaratKetentuan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/bantuan",
    name: "Bantuan",
    props: true,
    component: Bantuan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    props: true,
    component: Privacy,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/chats",
    name: "Chats",
    props: true,
    component: Chats,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/chats/list/:nakes_id/:patient_id",
    name: "ChatsList",
    props: true,
    component: ChatsList,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/brosurlist",
    name: "BrosurList",
    props: true,
    component: BrosurList,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/jadwalpelatihanlist",
    name: "JadwalPelatihanList",
    props: true,
    component: JadwalPelatihanList,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/agenda-pelatihan",
    name: "AgendaPelatihan",
    props: true,
    component: AgendaPelatihan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/master/jabatanlist",
    name: "JabatanList",
    props: true,
    component: Jabatan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/direksi",
    name: "DireksiList",
    props: true,
    component: Direksi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/katalogbuku",
    name: "KatalogBuku",
    props: true,
    component: KatalogBuku,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/katalogbuku-add",
    name: "KatalogBukuAdd",
    props: true,
    component: KatalogBukuAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/katalogbuku-edit/:id",
    name: "KatalogBukuEdit",
    props: true,
    component: KatalogBukuEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/berita",
    name: "BeritaList",
    props: true,
    component: Berita,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/berita-add",
    name: "BeritaAdd",
    props: true,
    component: BeritaAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/berita-edit/:id",
    name: "BeritaEdit",
    props: true,
    component: BeritaEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/keseminatan",
    name: "Keseminatan",
    props: true,
    component: Keseminatan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/keseminatan-add",
    name: "KeseminatanAdd",
    props: true,
    component: KeseminatanAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/keseminatan-edit/:id",
    name: "KeseminatanEdit",
    props: true,
    component: KeseminatanEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/testimoni",
    name: "TestimoniList",
    props: true,
    component: Testimoni,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/fasilitas",
    name: "FasilitasList",
    props: true,
    component: Fasilitas,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/fasilitator",
    name: "FasilitatorList",
    props: true,
    component: Fasilitator,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/struktur-organisasi",
    name: "StrukturOrganisasi",
    props: true,
    component: StrukturOrganisasi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/visi-misi",
    name: "VisiMisi",
    props: true,
    component: VisiMisi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/tentang-perdatin",
    name: "TentangPerdatin",
    props: true,
    component: TentangPerdatin,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/instagram",
    name: "Instagram",
    props: true,
    component: Instagram,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/seminat",
    name: "Seminat",
    props: true,
    component: Seminat,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/adrt",
    name: "AdRt",
    props: true,
    component: AdRt,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/pengurus",
    name: "Pengurus",
    props: true,
    component: Pengurus,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/penguruscabang",
    name: "PengurusCabang",
    props: true,
    component: PengurusCabang,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/penguruskati",
    name: "PengurusKati",
    props: true,
    component: PengurusKati,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/slider",
    name: "Slider",
    props: true,
    component: Slider,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/timeline",
    name: "Timeline",
    props: true,
    component: Timeline,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/komunitas",
    name: "Komunitas",
    props: true,
    component: Komunitas,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/master/profesi",
    name: "Profesi",
    props: true,
    component: Profesi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/jenisrs",
    name: "JenisRS",
    props: true,
    component: JenisRS,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kelasrs",
    name: "KelasRS",
    props: true,
    component: KelasRS,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kepemilikan",
    name: "Kepemilikan",
    props: true,
    component: Kepemilikan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/bidang-pengurus",
    name: "BidangPengurus",
    props: true,
    component: BidangPengurus,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/bidang-pengurus-edit/:id",
    name: "BidangPengurusEdit",
    props: true,
    component: BidangPengurusEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pengurus-pusat",
    name: "PengurusPusat",
    props: true,
    component: PengurusPusat,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pengurus-pusat-add",
    name: "PengurusPusatAdd",
    props: true,
    component: PengurusPusatAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pengurus-pusat-edit/:id",
    name: "PengurusPusatEdit",
    props: true,
    component: PengurusPusatEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pengurus-wilayah",
    name: "PengurusWilayah",
    props: true,
    component: PengurusWilayah,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pengurus-wilayah-add",
    name: "PengurusWilayahAdd",
    props: true,
    component: PengurusWilayahAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/pengurus-wilayah-edit/:id",
    name: "PengurusWilayahEdit",
    props: true,
    component: PengurusWilayahEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/calon-anggota",
    name: "CalonAnggota",
    props: true,
    component: CalonAnggota,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/fasyankes",
    name: "Fasyankes",
    props: true,
    component: Fasyankes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/fasyankes-add",
    name: "FasyankesAdd",
    props: true,
    component: FasyankesAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/fasyankes-edit/:id",
    name: "FasyankesEdit",
    props: true,
    component: FasyankesEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/pesanketuaperdatin",
    name: "PesanKapusdokkes",
    props: true,
    component: PesanKapusdokkes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/pesan-video",
    name: "PesanVideo",
    props: true,
    component: PesanVideo,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/programkerja",
    name: "ProgramKerja",
    props: true,
    component: ProgramKerja,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/ketuaperdatin",
    name: "KapusdokkesList",
    props: true,
    component: Kapusdokkes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/mitraperdatin",
    name: "MitraPusdokkes",
    props: true,
    component: MitraPusdokkes,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/galeri",
    name: "Galeri",
    props: true,
    component: Galeri,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/pengaduan",
    name: "Pengaduan",
    props: true,
    component: Pengaduan,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/advokasi",
    name: "Advokasi",
    props: true,
    component: Advokasi,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/webcontent/kontak",
    name: "Kontak",
    props: true,
    component: Kontak,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log("masuk sini");
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    console.log("masuk 2");
    next();
  }
});

export default router;
