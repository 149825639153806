<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div style="position: relative">
        <v-btn
          v-if="readOnly"
          absolute
          fab
          top
          right
          dark
          color="primary"
          @click="editItem"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <br />
      <br />
      <form>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-img
              v-if="sejarah.foto_url_original"
              :src="sejarah.foto_url"
              contain
              :key="sejarah.fotoKey"
              max-height="300"
            ></v-img>
            <v-img
              v-else
              src="@/assets/empty_image.png"
              contain
              max-height="300"
            ></v-img>
            <v-file-input
              class="mt-4"
              outlined
              @change="loadImage"
              accept="image/*"
              v-model="sejarah.poster_url"
              prepend-icon="mdi-image"
              show-size
              label="Gambar"
              :disabled="readOnly"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <h5>Konten Program Kerja</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" md="10">
            <vue-editor
              id="editor"
              useCustomImageHandler
              @image-added="handleImageAdded"
              v-model="sejarah.description"
              :disabled="readOnly"
            />
          </v-col>
        </v-row>
        <v-row v-if="!readOnly">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
            <v-btn dark color="primary" type="submit" @click.prevent="doSubmit"
              >Simpan</v-btn
            >
          </v-col>
        </v-row>
      </form>
    </v-container>
  </v-main>
</template>

<script>
import ProgramPrioritasService from "../../services/web_content/ProgramPrioritasService";
import CommonService from "../../services/CommonService";
import EventService from "../../services/EventService";
import { VueEditor } from "vue2-editor";
import { uuid } from "vue-uuid";

export default {
  name: "ProgramKerja",
  components: { VueEditor },
  props: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Program Kerja",
        disabled: false,
        exact: true,
        to: { name: "ProgramKerja" },
      },
      { text: "Detail", disabled: true },
    ],
    readOnly: true,
    sejarah: {
      uuid: uuid.v4(),
      id: null,
      foto_url_original: null,
      foto_url: null,
      fotoKey: 0,
      poster_url: null,
      description: "",
    },
  }),
  computed: {
    outlined() {
      return this.readOnly;
    },
    hideDetails() {
      return true;
    },
  },
  mounted: async function () {
    this.fetch();
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.sejarah.foto_url = e.target.result;
          this.sejarah.foto_url_original = this.sejarah.foto_url;
          this.sejarah.fotoKey++;
        };
        reader.readAsDataURL(data);
      }
    },
    fetch: async function () {
      try {
        console.log("fetch");
        ProgramPrioritasService.program()
          .then((res) => {
            this.sejarah = res.data.program;
            console.log(this.sejarah);
            console.log(this.sejarah.id);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.error("error:", e);
      }
    },

    editItem: async function () {
      this.readOnly = false;
    },

    doBatal: function () {
      this.readOnly = true;
    },

    doSubmit: async function () {
      try {
        console.log(this.sejarah.id);
        if (this.sejarah.id != null) {
          ProgramPrioritasService.update(this.sejarah)
            .then((res) => {
              console.log(res);
              CommonService.showSuccessToast("Update Program Kerja berhasil");

              this.fetch();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Update Program Kerja gagal. Coba lagi"
              );
            });
        } else {
          ProgramPrioritasService.create(this.sejarah)
            .then((res) => {
              console.log(res);
              this.dialog = false;
              CommonService.showSuccessToast("Tambah Program Kerja berhasil");

              this.getDataFromApi();
              this.readOnly = true;
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Tambah Program Kerja gagal. Coba lagi"
              );
            });
        }
      } catch (e) {
        console.error("error:", e);
      }
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //3 = program kerja
      EventService.uploadFotoKonten(this.sejarah.uuid, file, 3)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style lang="scss">
#editor {
  p {
    font-size: 14px;
    margin: 0px;
    line-height: 32px;
    margin: calc(1ex / 0.32) 0 !important;
    text-align: left;
    hyphens: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
    letter-spacing: -0.003em;
  }

  img {
    width: 100% !important;
    object-fit: cover;
  }
}
</style>
