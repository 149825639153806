import http from "../http-common";
/* eslint-disable */
class MasterIuranRekeningService {
  masterIuranRekeningList() {
    return http.get("/master-iuran-rekening-pusat", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  masterRekeningTabsolPusat() {
    return http.get("/master-rekening-tabsol-pusat", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  masterRekeningEventPusat() {
    return http.get("/master-rekening-event-pusat", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    if (data.iuran != null) bodyFormData.append("iuran", data.iuran);
    if (data.no_rekening != null)
      bodyFormData.append("no_rekening", data.no_rekening);
    if (data.nama_rekening != null)
      bodyFormData.append("nama_rekening", data.nama_rekening);
    if (data.virtual_account != null)
      bodyFormData.append("virtual_account", data.virtual_account);

    if (data.telp != null) bodyFormData.append("telp", data.telp);
    if (data.email != null) bodyFormData.append("email", data.email);
    if (data.user_tanggal_lahir != null)
      bodyFormData.append("user_tanggal_lahir", data.user_tanggal_lahir);
    if (data.user_tempat_lahir != null)
      bodyFormData.append("user_tempat_lahir", data.user_tempat_lahir);
    if (data.user_no_ktp != null)
      bodyFormData.append("user_no_ktp", data.user_no_ktp);

    if (data.user_kode_pos != null)
      bodyFormData.append("user_kode_pos", data.user_kode_pos);

    if (data.user_alamat != null)
      bodyFormData.append("user_alamat", data.user_alamat);

    if (data.user_npwp != null)
      bodyFormData.append("user_npwp", data.user_npwp);

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("user_gender", data.selectedJenisKelamin.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("user_propinsi", data.selectedPropinsi.code);
      bodyFormData.append("user_propinsi_name", data.selectedPropinsi.name);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("user_kabupaten", data.selectedKabupaten.code);
      bodyFormData.append("user_kabupaten_name", data.selectedKabupaten.name);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("user_kecamatan", data.selectedKecamatan.code);
      bodyFormData.append("user_kecamatan_name", data.selectedKecamatan.name);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("user_kelurahan", data.selectedKelurahan.code);
      bodyFormData.append("user_kelurahan_name", data.selectedKelurahan.name);
    }

    if (data.selectedBank && data.selectedBank != undefined) {
      bodyFormData.append("bank_code", data.selectedBank.code);
      bodyFormData.append("bank_name", data.selectedBank.name);
    }

    if (data.foto_tabungan != null)
      bodyFormData.append("foto_tabungan", data.foto_tabungan);

    if (data.user_ktp != null) bodyFormData.append("user_ktp", data.user_ktp);
    if (data.user_selfie != null)
      bodyFormData.append("user_selfie", data.user_selfie);

    return http.put(`/master-rekening-pusat/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateTabsol(data) {
    var bodyFormData = new FormData();
    if (data.iuran != null) bodyFormData.append("iuran", data.iuran);
    if (data.no_rekening != null)
      bodyFormData.append("no_rekening", data.no_rekening);
    if (data.nama_rekening != null)
      bodyFormData.append("nama_rekening", data.nama_rekening);
    if (data.virtual_account != null)
      bodyFormData.append("virtual_account", data.virtual_account);

    if (data.telp != null) bodyFormData.append("telp", data.telp);
    if (data.email != null) bodyFormData.append("email", data.email);
    if (data.user_tanggal_lahir != null)
      bodyFormData.append("user_tanggal_lahir", data.user_tanggal_lahir);
    if (data.user_tempat_lahir != null)
      bodyFormData.append("user_tempat_lahir", data.user_tempat_lahir);
    if (data.user_no_ktp != null)
      bodyFormData.append("user_no_ktp", data.user_no_ktp);

    if (data.user_kode_pos != null)
      bodyFormData.append("user_kode_pos", data.user_kode_pos);

    if (data.user_alamat != null)
      bodyFormData.append("user_alamat", data.user_alamat);

    if (data.user_npwp != null)
      bodyFormData.append("user_npwp", data.user_npwp);

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("user_gender", data.selectedJenisKelamin.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("user_propinsi", data.selectedPropinsi.code);
      bodyFormData.append("user_propinsi_name", data.selectedPropinsi.name);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("user_kabupaten", data.selectedKabupaten.code);
      bodyFormData.append("user_kabupaten_name", data.selectedKabupaten.name);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("user_kecamatan", data.selectedKecamatan.code);
      bodyFormData.append("user_kecamatan_name", data.selectedKecamatan.name);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("user_kelurahan", data.selectedKelurahan.code);
      bodyFormData.append("user_kelurahan_name", data.selectedKelurahan.name);
    }

    if (data.selectedBank && data.selectedBank != undefined) {
      bodyFormData.append("bank_code", data.selectedBank.code);
      bodyFormData.append("bank_name", data.selectedBank.name);
    }

    if (data.foto_tabungan != null)
      bodyFormData.append("foto_tabungan", data.foto_tabungan);

    if (data.user_ktp != null) bodyFormData.append("user_ktp", data.user_ktp);
    if (data.user_selfie != null)
      bodyFormData.append("user_selfie", data.user_selfie);

    return http.put(`/master-rekening-tabsol-pusat/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  updateEvent(data) {
    var bodyFormData = new FormData();
    if (data.iuran != null) bodyFormData.append("iuran", data.iuran);
    if (data.no_rekening != null)
      bodyFormData.append("no_rekening", data.no_rekening);
    if (data.nama_rekening != null)
      bodyFormData.append("nama_rekening", data.nama_rekening);
    if (data.virtual_account != null)
      bodyFormData.append("virtual_account", data.virtual_account);

    if (data.telp != null) bodyFormData.append("telp", data.telp);
    if (data.email != null) bodyFormData.append("email", data.email);
    if (data.user_tanggal_lahir != null)
      bodyFormData.append("user_tanggal_lahir", data.user_tanggal_lahir);
    if (data.user_tempat_lahir != null)
      bodyFormData.append("user_tempat_lahir", data.user_tempat_lahir);
    if (data.user_no_ktp != null)
      bodyFormData.append("user_no_ktp", data.user_no_ktp);

    if (data.user_kode_pos != null)
      bodyFormData.append("user_kode_pos", data.user_kode_pos);

    if (data.user_alamat != null)
      bodyFormData.append("user_alamat", data.user_alamat);

    if (data.user_npwp != null)
      bodyFormData.append("user_npwp", data.user_npwp);

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("user_gender", data.selectedJenisKelamin.code);
    }

    if (data.selectedPropinsi && data.selectedPropinsi != undefined) {
      bodyFormData.append("user_propinsi", data.selectedPropinsi.code);
      bodyFormData.append("user_propinsi_name", data.selectedPropinsi.name);
    }

    if (data.selectedKabupaten && data.selectedKabupaten != undefined) {
      bodyFormData.append("user_kabupaten", data.selectedKabupaten.code);
      bodyFormData.append("user_kabupaten_name", data.selectedKabupaten.name);
    }

    if (data.selectedKecamatan && data.selectedKecamatan != undefined) {
      bodyFormData.append("user_kecamatan", data.selectedKecamatan.code);
      bodyFormData.append("user_kecamatan_name", data.selectedKecamatan.name);
    }

    if (data.selectedKelurahan && data.selectedKelurahan != undefined) {
      bodyFormData.append("user_kelurahan", data.selectedKelurahan.code);
      bodyFormData.append("user_kelurahan_name", data.selectedKelurahan.name);
    }

    if (data.selectedBank && data.selectedBank != undefined) {
      bodyFormData.append("bank_code", data.selectedBank.code);
      bodyFormData.append("bank_name", data.selectedBank.name);
    }

    if (data.foto_tabungan != null)
      bodyFormData.append("foto_tabungan", data.foto_tabungan);

    if (data.user_ktp != null) bodyFormData.append("user_ktp", data.user_ktp);
    if (data.user_selfie != null)
      bodyFormData.append("user_selfie", data.user_selfie);

    return http.put(`/master-rekening-event-pusat/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new MasterIuranRekeningService();
