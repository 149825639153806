<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Berita</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title> Edit Berita </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="userData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="userData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Judul"
                          outlined
                          dense
                          v-model="userData.judul"
                          :rules="userData.namaRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Status"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedHide"
                          :items="items_hide"
                          item-text="value"
                          :readonly="isEditable == 0"
                          :rules="userData.hideRules"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Konten Berita</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="10" md="10" class="">
                    <vue-editor
                      id="editor"
                      v-model="userData.content"
                      useCustomImageHandler
                      @image-added="handleImageAdded"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import BeritaService from "../../services/web_content/BeritaService";
import { VueEditor } from "vue2-editor";
import EventService from "../../services/EventService";
import CommonService from "../../services/CommonService";
import { uuid } from "vue-uuid";

export default {
  name: "BeritaEdit",
  components: { VueEditor },
  props: {
    id: String,
  },
  data: () => ({
    komunitas_key: 0,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Berita",
        disabled: false,
        exact: true,
        to: { name: "Berita" },
      },
      { text: "Edit Berita", disabled: true },
    ],
    valid: true,
    userData: {
      uuid: uuid.v4(),
      FotoUrl: "",
      file_foto: null,
      judul: "",
      content: "",
      selectedHide: "",
      namaRules: [(v) => !!v || "Judul harus diisi"],
      hideRules: [(v) => !!v || "Is Hide harus diisi"],
    },
    items_hide: [
      {
        text: 1,
        value: "Published",
      },
      {
        text: 0,
        value: "Unpublished",
      },
    ],
    result: {},
    fotoKey: 0,
    searchNakes: "",
  }),
  mounted: async function() {
    this.getBerita();
  },
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
  },
  watch: {},
  methods: {
    changeEditable: function() {
      this.isEditable = 1;
    },

    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    getBerita() {
      BeritaService.getBerita(this.id)
        .then((res) => {
          console.log(res.data.user);
          this.userData.judul = res.data.berita.judul;
          this.userData.content = res.data.berita.html;

          this.userData.FotoUrl = res.data.berita.foto_url;
          this.fotoKey++;

          this.items_hide.forEach((entry) => {
            if (entry.text == res.data.berita.publish) {
              this.userData.selectedHide = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function() {
      this.$router.go();
    },

    doSubmit: async function() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      BeritaService.update(this.id, this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;

          CommonService.showSuccessToast("Update berita berhasil");
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update berita gagal. Coba lagi");
        });
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.userData.uuid, file, 2)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style lang="scss">
#editor {
  p {
    font-size: 14px;
    margin: 0px;
    line-height: 32px;
    margin: calc(1ex / 0.32) 0 !important;
    text-align: left;
    hyphens: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
    letter-spacing: -0.003em;
  }

  img {
    width: 100% !important;
    object-fit: cover;
  }
}
</style>
