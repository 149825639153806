<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Event </v-card-title>

        <v-fab-transition>
          <v-btn
            class="mt-8 mr-4"
            v-if="isEditable == 0"
            @click="changeEditable"
            color="pink"
            dark
            absolute
            top
            right
            fab
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>

        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img
                    :key="fotoKey"
                    :src="currentData.FotoUrl"
                    contain
                  ></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="currentData.file_foto"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                :disabled="isEditable == 0"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Judul"
                    dense
                    value=""
                    rows="3"
                    v-model="currentData.judul"
                    :rules="currentData.kegiatanTitleRules"
                    :readonly="isEditable == 0"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Tanggal Event"
                    v-model="currentData.tanggal_event"
                    type="date"
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Penyelenggara"
                    outlined
                    dense
                    v-model="currentData.penyelenggara"
                    required
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>Konten Event</h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="10" md="10" class="">
              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="currentData.konten"
                :disabled="isEditable == 0"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                @click="doBatal"
                style="margin-right: 10px"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import EventService from "../services/EventService";
import { VueEditor } from "vue2-editor";
import { uuid } from "vue-uuid";
import * as moment from "moment";

export default {
  name: "EventEvent",
  components: { VueEditor },
  props: {
    id: String,
  },
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Event",
        disabled: false,
        exact: true,
        to: { name: "Event" },
      },
      { text: "Tambah Event", disabled: true },
    ],
    valid: true,
    currentData: {
      uuid: uuid.v4(),
      FotoUrl: "",
      file_foto: null,
      id: "",
      judul: "",
      tanggal_event: "",
      penyelenggara: "",
      konten: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
  }),
  mounted: async function () {
    this.getEvent();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    getEvent() {
      EventService.getEvent(this.id)
        .then((res) => {
          console.log(res.data.event);
          this.currentData = res.data.event;
          this.currentData.tanggal_event = moment(
            this.currentData.tanggal_event
          ).format("YYYY-MM-DD");

          this.currentData.FotoUrl = res.data.event.foto_url;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      EventService.update(this.id, this.currentData, this.currentData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update event berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Update event gagal. Coba lagi");
        });
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(this.currentData.uuid, file, 1)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
