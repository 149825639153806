<template>
  <div>
    <v-card>
      <v-card-title>
        Kegiatan Detil <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Judul"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Kegiatan Detil</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-progress-linear
                        v-if="showProgressUpload"
                        v-model="progressUpload"
                        color="amber"
                        height="25"
                      >
                        <strong>{{ Math.ceil(progressUpload) }}%</strong>
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Judul"
                        required
                        v-model="currentData.title"
                        :rules="currentData.titleRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        label="Kategori"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedKategori"
                        :items="items_kategori"
                        item-text="value"
                        :rules="currentData.kategoriRules"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Urutan"
                        required
                        v-model="currentData.urutan"
                        :rules="currentData.urutanRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 3
                      "
                    >
                      <v-text-field
                        label="Tanggal"
                        v-model="currentData.tanggal_pretest"
                        type="date"
                        dense
                        :rules="currentData.tanggalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 3
                      "
                    >
                      <v-text-field
                        label="Jam Awal"
                        v-model="currentData.jam_awal_pretest"
                        type="time"
                        dense
                        :rules="currentData.jamAwalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 3
                      "
                    >
                      <v-text-field
                        label="Jam Akhir"
                        v-model="currentData.jam_akhir_pretest"
                        type="time"
                        dense
                        :rules="currentData.jamAkhirRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 4
                      "
                    >
                      <v-text-field
                        label="Tanggal"
                        v-model="currentData.tanggal_posttest"
                        type="date"
                        dense
                        :rules="currentData.tanggalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 4
                      "
                    >
                      <v-text-field
                        label="Jam Awal"
                        v-model="currentData.jam_awal_posttest"
                        type="time"
                        dense
                        :rules="currentData.jamAwalRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 4
                      "
                    >
                      <v-text-field
                        label="Jam Akhir"
                        v-model="currentData.jam_akhir_posttest"
                        type="time"
                        dense
                        :rules="currentData.jamAkhirRules"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code == 1
                      "
                      cols="12"
                    >
                      <v-file-input
                        accept="video/mp4,video/x-m4v,video/*"
                        v-model="currentData.video"
                        prepend-icon="mdi-file"
                        show-size
                        label="Video"
                      />
                    </v-col>
                    <v-col
                      v-if="
                        currentData.selectedKategori &&
                        currentData.selectedKategori.code != 6 &&
                        currentData.selectedKategori.code != 1 &&
                        currentData.selectedKategori.code != 3 &&
                        currentData.selectedKategori.code != 4
                      "
                      cols="12"
                    >
                      <v-file-input
                        accept="application/pdf"
                        v-model="currentData.file_pdf"
                        prepend-icon="mdi-file"
                        show-size
                        label="File PDF"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!showProgressUpload">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :single-select="true"
        show-select
      >
        <template v-slot:[`item.kategori`]="{ item }">
          <v-chip :color="getColor(item.kategori)" dark>
            {{ getKategoriDescription(item.kategori) }}
          </v-chip>
        </template>
        <template v-slot:[`item.video`]="{ item }">
          <a v-if="item.video_original" :href="item.video" target="_blank">
            Link Video
          </a>
          <span v-else></span>
        </template>
        <template v-slot:[`item.file_pdf`]="{ item }">
          <a
            v-if="item.file_pdf_original"
            :href="item.file_pdf"
            target="_blank"
          >
            Link File
          </a>
          <span v-else></span>
        </template>
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:[`item.tanggal_pretest`]="{ item }">
          {{ getCurrentDate(item.tanggal_pretest) }} <br />
          <span v-if="item.jam_awal_pretest">
            Jam : {{ item.jam_awal_pretest }} - {{ item.jam_akhir_pretest }}
          </span>
        </template>
        <template v-slot:[`item.tanggal_posttest`]="{ item }">
          {{ getCurrentDate(item.tanggal_posttest) }} <br />
          <span v-if="item.jam_awal_posttest">
            Jam : {{ item.jam_awal_posttest }} - {{ item.jam_akhir_posttest }}
          </span>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PelatihanPreTestComponent
        v-if="
          selected != undefined &&
          selected.length > 0 &&
          selected[0].kategori == 3
        "
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>

    <v-card class="mt-4">
      <PelatihanPostTestComponent
        v-if="
          selected != undefined &&
          selected.length > 0 &&
          selected[0].kategori == 4
        "
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>

    <v-card
      v-if="
        selected != undefined &&
        selected.length > 0 &&
        selected[0].kategori == 6
      "
      class="mt-4"
    >
      <v-tabs>
        <v-tab>Kelas Online</v-tab>
        <v-tab>Narasumber</v-tab>
        <v-tab-item>
          <KelasOnlineComponent
            :id="selected[0].id"
            ref="mychild"
            :key="childKey"
            :kegiatan_id="kegiatan_id"
          />
        </v-tab-item>
        <v-tab-item>
          <PelatihanNarasumberComponent
            :id="selected[0].id"
            ref="mychild"
            :key="childKey"
            :kegiatan_id="kegiatan_id"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-card
      v-if="
        selected != undefined &&
        selected.length > 0 &&
        selected[0].kategori == 7
      "
      class="mt-4"
    >
      <v-tabs>
        <v-tab>Kelas Offline</v-tab>
        <v-tab>Narasumber</v-tab>
        <v-tab-item>
          <KelasOfflineComponent
            :id="selected[0].id"
            ref="mychild"
            :key="childKey"
            :kegiatan_id="kegiatan_id"
          />
        </v-tab-item>
        <v-tab-item>
          <PelatihanNarasumberComponent
            :id="selected[0].id"
            ref="mychild"
            :key="childKey"
            :kegiatan_id="kegiatan_id"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import PelatihanDetailService from "../../services/PelatihanDetailService";
import CommonService from "../../services/CommonService";

import PelatihanPreTestComponent from "./PelatihanPreTest.vue";
import PelatihanPostTestComponent from "./PelatihanPostTest.vue";
import PelatihanNarasumberComponent from "./PelatihanNarasumber.vue";
import KelasOnlineComponent from "./KelasOnlineEdit.vue";
import KelasOfflineComponent from "./KelasOfflineEdit.vue";
import * as moment from "moment";

export default {
  props: {
    id: Number,
    kegiatan_id: String,
  },
  components: {
    PelatihanPreTestComponent,
    PelatihanPostTestComponent,
    PelatihanNarasumberComponent,
    KelasOnlineComponent,
    KelasOfflineComponent,
  },
  data: () => ({
    showProgressUpload: false,
    progressUpload: 0,
    childKey: 0,
    selected: [],
    isEditable: 0,
    valid: true,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "title", sortable: false },
      { text: "Kategori", value: "kategori", sortable: false },
      { text: "Urutan", value: "urutan", sortable: false },
      { text: "Video", value: "video", sortable: false },
      { text: "File", value: "file_pdf", sortable: false },
      { text: "Waktu Pre Test", value: "tanggal_pretest", sortable: false },
      { text: "Waktu Post Test", value: "tanggal_posttest", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      pelatihan_sub_category_id: "",
      id: null,
      title: "",
      urutan: "",
      video: null,
      file_pdf: null,
      description: "",
      selectedKategori: "",
      tanggal_pretest: "",
      jam_awal_pretest: "",
      jam_akhir_pretest: "",
      tanggal_posttest: "",
      jam_awal_posttest: "",
      jam_akhir_posttest: "",
      titleRules: [(v) => !!v || "Judul harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      jamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      jamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      videoRules: [(v) => !!v || "Video harus diisi"],
    },

    items_kategori: [
      { code: 1, value: "Video" },
      { code: 2, value: "File" },
      { code: 3, value: "Pre Test" },
      { code: 4, value: "Post Test" },
      { code: 5, value: "Informed Consent" },
      { code: 6, value: "Kelas Online" },
      { code: 7, value: "Kelas Offline" },
    ],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      if (tanggal) {
        return moment(tanggal).format("DD/MM/YYYY");
      } else {
        return "";
      }
    },

    getColor(kategori) {
      switch (kategori) {
        case 1:
          return "red";
        case 2:
          return "green";
        case 3:
          return "orange";
        case 4:
          return "blue";
        case 5:
          return "cyan";
        case 6:
          return "pink";
        case 7:
          return "primary";
      }
    },

    getKategoriDescription(kategori) {
      switch (kategori) {
        case 1:
          return "Video";
        case 2:
          return "File";
        case 3:
          return "Pre Test";
        case 4:
          return "Post Test";
        case 5:
          return "Informed Consent";
        case 6:
          return "Kelas Online";
        case 7:
          return "Kelas Offline";
      }
    },

    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PelatihanDetailService.pelatihanDetailList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.isEditable = 1;

      this.currentData.title = "";
      this.currentData.urutan = "";
      this.currentData.video = null;
      this.currentData.selectedKategori = "";
      this.currentData.tanggal_pretest = "";
      this.currentData.jam_awal_pretest = "";
      this.currentData.jam_akhir_pretest = "";

      this.currentData.tanggal_posttest = "";
      this.currentData.jam_awal_posttest = "";
      this.currentData.jam_akhir_posttest = "";
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData.title = this.editedItem.title;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.id = this.editedItem.id;

      if (this.editedItem.tanggal_pretest) {
        this.currentData.tanggal_pretest = moment(
          this.editedItem.tanggal_pretest
        ).format("YYYY-MM-DD");
      }

      this.currentData.jam_awal_pretest = this.editedItem.jam_awal_pretest;
      this.currentData.jam_akhir_pretest = this.editedItem.jam_akhir_pretest;

      if (this.editedItem.tanggal_posttest) {
        this.currentData.tanggal_posttest = moment(
          this.editedItem.tanggal_posttest
        ).format("YYYY-MM-DD");
      }
      this.currentData.jam_awal_posttest = this.editedItem.jam_awal_posttest;
      this.currentData.jam_akhir_posttest = this.editedItem.jam_akhir_posttest;

      if (this.editedItem.kategori) {
        this.items_kategori.forEach((entry) => {
          if (entry.code == this.editedItem.kategori) {
            this.currentData.selectedKategori = entry;
            this.currentData.kategori = entry.code;
          }
        });
      }

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.showProgressUpload = true;
      this.progressUpload = 0;

      var config = {
        onUploadProgress: function (progressEvent) {
          console.log("progress upload");
          console.log(progressEvent.loaded);
          console.log(progressEvent.total);

          var myProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          this.progressUpload = myProgress;
        }.bind(this),
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (this.currentData.id == null) {
        this.currentData.pelatihan_sub_category_id = this.id;
        PelatihanDetailService.create(this.currentData, config)
          .then((res) => {
            this.showProgressUpload = false;
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah detil kegiatan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            this.showProgressUpload = false;
            console.log(err);

            CommonService.showSuccessToast(
              "Tambah detil pelatihan gagal. Coba lagi"
            );
          });
      } else {
        this.currentData.pelatihan_sub_category_id = this.id;

        PelatihanDetailService.update(this.currentData, config)
          .then((res) => {
            this.showProgressUpload = false;
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update detil kegiatan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            this.showProgressUpload = false;
            console.log(err);

            CommonService.showSuccessToast(
              "Update detil kegiatan gagal. Coba lagi"
            );
          });
      }
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PelatihanDetailService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus detil kegiatan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast(
            "Hapus detil kegiatan gagal. Coba lagi"
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
