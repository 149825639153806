<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-progress-linear
        v-if="isProgressBarShow"
        indeterminate
        color="green"
      ></v-progress-linear>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Anggota
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- <router-link
            to="/nakes-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="primary" dark class="mb-2"> Tambah </v-btn>
          </router-link> -->
          <v-btn
            color="primary"
            dark
            class="mb-2 ml-4"
            @click="onDownloadAnggota()"
          >
            Download
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogApprove" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mengapprove penghapusan anggota
                ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeApprove"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="approveItemConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogReject" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan membatalkan penghapusan anggota
                ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeReject"
                      >Tidak</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="rejectItemConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogApproveAnumerta" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mengapprove perubahan anumerta ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeApproveAnumerta"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="approveAnumertaItemConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogRejectAnumerta" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan membatalkan perubahan anumerta ini?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeRejectAnumerta"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="rejectAnumertaItemConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogJadikanAnumerta" max-width="700px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menjadikan anggota ini sebagai
                Anumerta?</v-card-title
              >
              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeJadikanAnumerta"
                      >Tidak</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="jadikanAnumertaConfirm"
                      >Ya</v-btn
                    >
                  </v-row>
                </v-container>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Tahun"
              dense
              outlined
              v-model="selectedTahun"
              :items="items_tahun"
              item-text="nama"
              @change="changeTahun"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="noNpa"
              append-icon="mdi-magnify"
              label="Cari No NPA"
              single-line
              outlined
              dense
              hide-details
              v-on:keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Status"
              dense
              outlined
              clearable
              v-model="selectedStatus"
              :items="items_status"
              item-text="nama"
              @change="changeStatus"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.is_aktif`]="{ item }">
            <v-chip :color="getColor(item.is_aktif, item.data_status)" dark>
              {{ getStatusKeanggotaan(item.is_aktif, item.data_status) }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-menu v-if="item.data_status == 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-3" medium v-bind="attrs" v-on="on">
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="jadikanAnumerta(item)">
                    <v-list-item-title>Jadikan Anumerta</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                v-if="
                  item.data_status == 2 &&
                    (item.is_approver == 1 || getRoleId == 1)
                "
                medium
                class="mr-3"
                @click="approveItem(item)"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-if="
                  item.data_status == 2 &&
                    (item.is_approver == 1 || getRoleId == 1)
                "
                medium
                class="mr-3"
                @click="rejectItem(item)"
              >
                mdi-close
              </v-icon>
              <v-icon
                v-if="
                  item.data_status == 4 &&
                    (item.is_approver == 1 || getRoleId == 1)
                "
                medium
                class="mr-3"
                @click="approveAnumertaItem(item)"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-if="
                  item.data_status == 4 &&
                    (item.is_approver == 1 || getRoleId == 1)
                "
                medium
                class="mr-3"
                @click="rejectAnumertaItem(item)"
              >
                mdi-close
              </v-icon>
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="item.data_status == 1"
                medium
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import ReportService from "../services/ReportService";
import CommonService from "../services/CommonService";
import store from "../store/index";

export default {
  data: () => ({
    isProgressBarShow: false,
    dialogApprove: false,
    dialogReject: false,
    dialogApproveAnumerta: false,
    dialogJadikanAnumerta: false,
    dialogRejectAnumerta: false,
    breadcrumbs: [
      {
        text: "Data Anggota",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    tahun: "",
    noNpa: "",
    selectedPropinsi: "",
    selectedTahun: "",
    selectedStatus: "",
    items_propinsi: [],
    items_tahun: [],
    items_status: [
      {
        id: 1,
        nama: "Aktif",
      },
      { id: 0, nama: "Tidak Aktif" },
    ],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "No NPA", value: "no_npa", sortable: false },
      { text: "Cabang", value: "propinsi_nama", sortable: false },
      { text: "No Telp", value: "phone", sortable: false },
      { text: "Status", value: "is_aktif", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.selectedTahun = new Date().getFullYear();

    this.getListPropinsi();
  },
  methods: {
    getColor(dataStatus, status) {
      if (status == 1) {
        switch (dataStatus) {
          case 1:
            return "blue";
          default:
            return "red";
        }
      } else {
        return "";
      }
    },
    getStatusKeanggotaan(id, status) {
      if (status == 1) {
        if (id == 1) {
          return "Aktif";
        } else {
          return "Tidak Aktif";
        }
      } else {
        if (status == 1) {
          return "Aktif";
        } else if (status == 2) {
          return "Menunggu Konfirmasi Penghapusan Anggota";
        } else if (status == 3) {
          return "Tidak Aktif";
        } else if (status == 4) {
          return "Menunggu Konfirmasi Perubahan Anumerta";
        } else if (status == 5) {
          return "Anumerta";
        }
      }
    },
    getStatus(id) {
      if (id == 1) {
        return "Aktif";
      } else if (id == 2) {
        return "Menunggu Konfirmasi Penghapusan Anggota";
      } else if (id == 3) {
        return "Tidak Aktif";
      } else if (id == 4) {
        return "Menunggu Konfirmasi Perubahan Anumerta";
      } else if (id == 5) {
        return "Anumerta";
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var propinsiId = "";

      if (this.selectedPropinsi) propinsiId = this.selectedPropinsi.id;

      // if (this.getRoleId == 3) {
      // propinsiId = this.getCabangPengurusId;
      // }

      var tahun = new Date().getFullYear();

      if (this.selectedTahun) {
        tahun = this.selectedTahun;
      }

      var status = "";

      if (this.selectedStatus) {
        status = this.selectedStatus.id;
      }

      NakesService.nakesList(
        page,
        itemsPerPage,
        this.search,
        propinsiId,
        this.noNpa,
        tahun,
        status
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    onDownloadAnggota() {
      this.isProgressBarShow = true;

      var propinsiId = "";

      if (this.selectedPropinsi) propinsiId = this.selectedPropinsi.id;

      ReportService.downloadAnggotaReport(this.search, propinsiId, this.noNpa)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.isProgressBarShow = false;
        })
        .catch((err) => {
          this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/nakes-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      NakesService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Mohon menunggu konfirmasi penghapusan anggota", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;

          if (this.getCabangPengurusId) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async changePropinsi() {
      this.getDataFromApi();
    },

    async changeTahun() {
      this.getDataFromApi();
    },

    async changeStatus() {
      this.getDataFromApi();
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    rejectItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReject = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeReject() {
      this.dialogReject = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeApproveAnumerta() {
      this.dialogApproveAnumerta = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeRejectAnumerta() {
      this.dialogRejectAnumerta = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    approveAnumertaItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApproveAnumerta = true;
    },

    rejectAnumertaItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRejectAnumerta = true;
    },

    approveItemConfirm() {
      NakesService.approveDelete(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApprove();

          console.log(res);
          this.$toast.success("Approve penghapusan anggota berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Approve penghapusan anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    rejectItemConfirm() {
      NakesService.rejectDelete(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeReject();

          console.log(res);
          this.$toast.success("Pembatalan penghapusan anggota berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Pembatalan penghapusan anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    jadikanAnumerta(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogJadikanAnumerta = true;
    },

    closeJadikanAnumerta() {
      this.dialogJadikanAnumerta = false;
    },

    jadikanAnumertaConfirm() {
      NakesService.jadikanAnumerta(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeJadikanAnumerta();

          console.log(res);
          this.$toast.success(
            "Mohon menunggu pengurus untuk mengubah status Anumerta",
            {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            }
          );

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Pengajuan anumerta gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    approveAnumertaItemConfirm() {
      NakesService.approveAnumerta(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeApproveAnumerta();

          console.log(res);
          this.$toast.success("Anggota telah menjadi Anumerta berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Set Anumerta gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    rejectAnumertaItemConfirm() {
      NakesService.rejectAnumerta(this.editedItem.id, this.currentData)
        .then((res) => {
          this.closeRejectAnumerta();

          console.log(res);
          this.$toast.success("Pembatalan status Anumerta berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Pembatalan status Anumerta gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
    isCabangPengurus() {
      if (store.getters.getUser.cabang_pengurus_id) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
