<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Event</v-tab>
          <v-tab>Workshop</v-tab>
          <v-tab>Paket Wisata</v-tab>
          <v-tab>Speaker</v-tab>
          <v-tab>Hotel</v-tab>
          <v-tab>Contact Person</v-tab>
          <v-tab>Peserta</v-tab>
          <v-tab>Invoice</v-tab>
          <v-tab>E-Poster</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="pl-4 pr-4 pt-4 pb-4">
              <v-card-title> Event Perdatin </v-card-title>
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pl-4 pr-4"
                >
                  <v-row dense>
                    <v-col cols="12" sm="6" md="6" class="pr-4">
                      <v-sheet color="white" elevation="2">
                        <v-responsive :aspect-ratio="16 / 9">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                          ></v-img>
                        </v-responsive>
                      </v-sheet>
                      <v-file-input
                        @change="loadImage"
                        accept="image/*"
                        v-model="currentData.file_foto"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        :disabled="isEditable == 0"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            name="input-7-4"
                            label="Judul"
                            dense
                            rows="3"
                            v-model="currentData.nama"
                            :rules="currentData.namaRules"
                            :readonly="isEditable == 0"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-row dense>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Awal"
                                v-model="currentData.tanggal_awal"
                                type="date"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.tanggalAwalRules"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                              <v-text-field
                                label="Tanggal Akhir"
                                v-model="currentData.tanggal_akhir"
                                type="date"
                                dense
                                :readonly="isEditable == 0"
                                :rules="currentData.tanggalAkhirRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Penyelenggara"
                                outlined
                                dense
                                v-model="currentData.penyelenggara"
                                required
                                :readonly="isEditable == 0"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                label="Link Google Form"
                                outlined
                                dense
                                v-model="currentData.link_google_form"
                                required
                                :readonly="isEditable == 0"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h5>Konten Event</h5>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="10" md="10">
                      <vue-editor
                        id="editor"
                        useCustomImageHandler
                        @image-added="handleImageAdded"
                        v-model="currentData.deskripsi"
                        style="color:#282c2c;"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn
                        v-if="isEditable == 1"
                        @click="doBatal"
                        style="margin-right: 10px"
                        >Batal</v-btn
                      >
                      <v-btn
                        v-if="isEditable == 1"
                        dark
                        color="primary"
                        type="button"
                        @click="doSubmit"
                        >Simpan</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <WorkshopComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PaketWisataComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <SpeakerComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <HotelComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <ContactPersonComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PesertaPibComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <InvoicePibComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <EPosterPibComponent :id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import PibService from "../services/PibService";
import MasterService from "../services/MasterService";
import * as moment from "moment";
import WorkshopComponent from "./pib/Workshop.vue";
import PaketWisataComponent from "./pib/PaketWisata.vue";
import SpeakerComponent from "./pib/Speaker.vue";
import HotelComponent from "./pib/Hotel.vue";
import ContactPersonComponent from "./pib/ContactPerson.vue";
import PesertaPibComponent from "./pib/PesertaPib.vue";
import InvoicePibComponent from "./pib/InvoicePib.vue";
import EPosterPibComponent from "./pib/EPosterPib.vue";
import EventService from "../services/EventService";

import { VueEditor } from "vue2-editor";
import { uuid } from "vue-uuid";

export default {
  name: "EventPerdatinEdit",
  components: {
    WorkshopComponent,
    PaketWisataComponent,
    SpeakerComponent,
    HotelComponent,
    ContactPersonComponent,
    PesertaPibComponent,
    InvoicePibComponent,
    EPosterPibComponent,
    VueEditor,
  },
  props: {
    id: String,
  },
  data: () => ({
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Event",
        disabled: false,
        exact: true,
        to: { name: "EventPerdatinEdit" },
      },
      { text: "Edit Event", disabled: true },
    ],
    valid: true,
    currentData: {
      uuid: uuid.v4(),
      FotoUrl: "",
      FotoUrlSambutan: "",
      file_foto: null,
      file_foto_sambutan: null,
      sambutan_kalimat: "",
      id: "",
      nama: "",
      deskripsi: "",
      penyelenggara: "",
      link_google_form: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      simposium_lokasi: "",
      simposium_tanggal_awal: "",
      simposium_tanggal_akhir: "",
      simposium_kuota: "",
      simposium_jam_awal: "",
      simposium_jam_akhir: "",
      simposium_peserta: "",
      simposium_harga: "",
      venue_nama: "",
      venue_alamat: "",
      venue_tanggal_awal: "",
      venue_tanggal_akhir: "",
      venue_jam_awal: "",
      venue_jam_akhir: "",
      venue_pic_nama: "",
      venue_pic_phone: "",
      venue_link_google_maps: "",
      fotoRules: [(v) => !!v || "Foto harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      deskripsiRules: [(v) => !!v || "Deskripsi harus diisi"],
      tanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      simposiumLokasiRules: [(v) => !!v || "Lokasi harus diisi"],
      simposiumTanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      simposiumTanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      simposiumKuotaRules: [(v) => !!v || "Kuota harus diisi"],
      simposiumJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      simposiumJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      simposiumPesertaRules: [(v) => !!v || "Peserta harus diisi"],
      simposiumHargaRules: [(v) => !!v || "Harga harus diisi"],
      venueNamaRules: [(v) => !!v || "Nama Venue harus diisi"],
      venueAlamatRules: [(v) => !!v || "Alamat harus diisi"],
      venueTanggalAwalRules: [(v) => !!v || "Tanggal Awal harus diisi"],
      venueTanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      venueJamAwalRules: [(v) => !!v || "Jam Awal harus diisi"],
      venueJamAkhirRules: [(v) => !!v || "Jam Akhir harus diisi"],
      venuePicNamaRules: [(v) => !!v || "Nama PIC harus diisi"],
      venuePicPhoneRules: [(v) => !!v || "No Telp PIC harus diisi"],
      venueLinkGoogleMapRules: [(v) => !!v || "Link Google Map harus diisi"],
      sambutanKalimatRulesRules: [(v) => !!v || "Sambutan harus diisi"],
    },
    items_kategori: [],
    fotoKey: 0,
    fotoKeySambutan: 0,
  }),
  mounted: async function() {
    this.getPib();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
  },
  methods: {
    changeEditable: function() {
      this.isEditable = 1;
    },

    loadImage: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    loadImageSambutan: function(data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrlSambutan = e.target.result;
          this.fotoKeySambutan++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrlSambutan = "";
        this.currentData.file_foto_sambutan = null;
      }
    },

    getPib() {
      PibService.getPib(this.id)
        .then((res) => {
          this.currentData.nama = res.data.pib.nama;
          this.currentData.deskripsi = res.data.pib.deskripsi;
          this.currentData.penyelenggara = res.data.pib.penyelenggara;
          this.currentData.tanggal_awal = moment(
            res.data.pib.tanggal_awal
          ).format("YYYY-MM-DD");
          this.currentData.tanggal_akhir = moment(
            res.data.pib.tanggal_akhir
          ).format("YYYY-MM-DD");
          this.currentData.link_google_form = res.data.pib.link_google_form;

          if (res.data.pib.photo) this.currentData.FotoUrl = res.data.pib.photo;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function() {
      this.isEditable = 0;
    },

    doSubmit: async function() {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      PibService.update(this.id, this.currentData, this.currentData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Edit Event Perdatin berhasil");

          this.isEditable = 0;
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Edit Event Perdatin gagal. Coba lagi");
        });
    },

    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //4 = event perdatin
      EventService.uploadFotoKonten(this.currentData.uuid, file, 4)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(cursorLocation, "image", res.data.event.foto_url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
