<template>
  <div>
    <v-card>
      <v-card-title>
        Master Iuran Cabang
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn color="purple" dark class="mb-2" @click="doAdd"> Tambah </v-btn>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Master Iuran</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        label="Cabang Perdatin"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedPropinsi"
                        :items="items_cabang"
                        item-text="name"
                        :disabled="
                          getCabangPengurusId != null || isCabangDisabled
                        "
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        label="Tahun"
                        clearable
                        dense
                        outlined
                        v-model="currentData.tahun"
                        :items="items_tahun"
                        item-text="name"
                        :rules="currentData.tahunRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Nominal"
                        outlined
                        dense
                        required
                        v-model="currentData.nominal"
                        :rules="currentData.nominalRules"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>

      <v-row class="mx-6 mb-6">
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Cabang Perdatin"
            clearable
            dense
            outlined
            v-model="search.selectedPropinsi"
            :items="items_cabang"
            item-text="name"
            @change="changePropinsi"
            :disabled="getCabangPengurusId != null"
          ></v-combobox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            label="Tahun"
            clearable
            dense
            outlined
            v-model="search.tahun"
            :items="items_tahun"
            item-text="name"
            @change="getDataFromApi"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.kategori`]="{ item }">
          <v-chip :color="getColorKategori(item.kategori)" dark>
            {{ getKategori(item.kategori) }}
          </v-chip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ getStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.foto_url`]="{ item }">
          <v-img
            style="cursor: pointer"
            @click="showFotoBuktiBayar(item.foto_url)"
            height="auto"
            width="100"
            :src="item.foto_url"
          ></v-img>
        </template>
        <template v-slot:[`item.nominal`]="{ item }">
          {{ new Intl.NumberFormat("id-ID").format(item.nominal) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <AnggotaSelectComponent
      @pilihAnggota="pilihAnggota"
      ref="modalAnggota"
      v-model="dialogAnggota"
    />
  </div>
</template>

<script>
import MasterIuranService from "../services/MasterIuranService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import NakesService from "../services/NakesService";
import * as moment from "moment";

import AnggotaSelectComponent from "../components/AnggotaSelect.vue";
import store from "../store/index";

export default {
  components: {
    AnggotaSelectComponent,
  },
  data: () => ({
    isCabangDisabled: false,
    dialogBuktiBayar: false,
    dialogAnggota: false,
    dialogApprove: false,
    dialogUpdate: false,
    valid: true,
    selected: [],
    breadcrumbs: [
      {
        text: "Data Iuran & TABSOL",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: {
      nama: "",
      tahun: "",
      selectedKategori: "",
      selectedStatus: "",
      selectedPropinsi: "",
    },
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Cabang", value: "cabang_nama", sortable: false },
      { text: "Tahun", value: "tahun", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      user_id: "",
      no_telepon: "",
      pengaduan: "",
      selectedDivisi: "",
      selectedJabatan: "",
      selectedCabangTujuan: "",
      selectedKategori: "",
      selectedPropinsi: "",
      no_npa: "",
      cabang_asal_nama: "",
      cabang_asal: "",
      tahun: "",
      nominal: "",
      langsung_setujui: false,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      pengaduanRules: [(v) => !!v || "Pengaduan harus diisi"],
      divisiRules: [(v) => !!v || "Bidang harus diisi"],
      jabatanRules: [(v) => !!v || "Jabatan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
      tahunRules: [(v) => !!v || "Tahun harus diisi"],
      nominalRules: [(v) => !!v || "Nominal harus diisi"],
      noTelpRules: [
        (v) => !!v || "No Telp harus diisi",
        (v) =>
          (v && v.length >= 11 && v.length <= 12) ||
          "No Telp harus 11-12 karakter",
      ],
      ktpRules: [
        (v) => !!v || "KTP harus diisi",
        (v) => (v && v.length == 16) || "Panjang KTP harus 16 karakter",
      ],
    },

    items_jabatan: [],
    items_divisi: [],
    items_cabang: [],
    items_tahun: [],
    items_kategori: [
      {
        id: 1,
        name: "Pembayaran Iuran pendaftaran Anggota",
      },
      {
        id: 2,
        name: "Pembayaran Tabsol",
      },
      {
        id: 3,
        name: "Perpanjangan Iuran Anggota",
      },
      {
        id: 4,
        name: "Upload Data Iuran Lama",
      },
    ],
    items_status: [
      {
        id: 1,
        name: "Menunggu Pembayaran Anggota",
      },
      {
        id: 2,
        name: "Menunggu Konfirmasi",
      },
      {
        id: 3,
        name: "Terverifikasi",
      },
    ],

    fotoBuktiBayar: null,
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    for (var i = new Date().getFullYear(); i >= 1990; i--) {
      this.items_tahun.push(i);
    }

    this.getListPropinsi();
    this.getDataFromApi();
  },
  methods: {
    getKategori(id) {
      if (id == 1) {
        return "Pembayaran Iuran pendaftaran Anggota";
      } else if (id == 2) {
        return "Pembayaran Tabsol";
      } else if (id == 3) {
        return "Pembayaran perpanjangan Iuran Anggota";
      } else if (id == 4) {
        return "Upload Data Iuran Lama";
      }
    },
    async getListOrganisasiDivisi() {
      MasterService.organisasiDivisiList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_divisi = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListOrganisasiJabatan() {
      MasterService.organisasiJabatanList()
        .then((res) => {
          this.$nextTick(() => {
            this.items_jabatan = res.data.list_data;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getColorKategori(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "pink lighten-3";
        case 2:
          return "purple lighten-3";
        case 3:
          return "teal lighten-3";
        default:
          return "red lighten-3";
      }
    },

    getColor(dataStatus) {
      console.log(dataStatus);
      switch (dataStatus) {
        case 1:
          return "blue";
        case 2:
          return "green";
        default:
          return "cyan";
      }
    },

    getStatus(id) {
      if (id == 1) {
        return "Menunggu Pembayaran Anggota";
      } else if (id == 2) {
        return "Menunggu Konfirmasi";
      } else if (id == 3) {
        return "Terverifikasi";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getEllipse(konten) {
      if (konten && konten.length > 100) {
        return konten.substring(1, 100) + "...";
      } else {
        return konten;
      }
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var tahun = "";
      var kategori = "";
      var status = "";
      var propinsi = "";

      if (this.search.tahun) {
        tahun = this.search.tahun;
      }

      if (this.search.selectedKategori) {
        kategori = this.search.selectedKategori.id;
      }

      if (this.search.selectedStatus) {
        status = this.search.selectedStatus.id;
      }

      if (this.search.selectedPropinsi) {
        propinsi = this.search.selectedPropinsi.id;
      }

      MasterIuranService.iuranList(
        page,
        itemsPerPage,
        this.search.nama,
        tahun,
        kategori,
        status,
        propinsi
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.urutan = "";
      this.currentData.no_npa = "";
      this.currentData.tahun = "";
      this.currentData.nominal = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = "";
      this.currentData.langsung_setujui = false;
      this.currentData.selectedDivisi = "";
      this.currentData.selectedJabatan = "";
      this.currentData.cabang_asal_nama = "";
      this.currentData.cabang_asal = "";
      this.currentData.selectedKategori = null;
      this.currentData.langsung_setujui = false;
      // this.currentData.selectedPropinsi = "";
      this.isCabangDisabled = false;

      console.log("do add");
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id != null) {
        MasterIuranService.update(this.currentData.id, this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update master iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update master iuran gagal. Coba lagi"
            );
          });
      } else {
        MasterIuranService.create(this.currentData, this.currentData.foto_url)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah master iuran berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah master iuran gagal. Coba lagi"
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.isCabangDisabled = true;
      this.currentData.tahun = this.editedItem.tahun;
      this.currentData.nominal = this.editedItem.nominal;
      this.currentData.id = this.editedItem.id;

      this.items_cabang.forEach((entry) => {
        if (entry.id == this.editedItem.cabang_id) {
          this.currentData.selectedPropinsi = entry;
        }
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MasterIuranService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus master iuran berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus master iuran gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changeFile: function (file) {
      console.log("changeMateriFile:", file);
      console.log(file.name);
      this.currentData.fileKey++;
    },

    openAnggotaModal: async function () {
      this.$refs.modalAnggota.getDataFromApi();
      this.dialogAnggota = true;
    },

    pilihAnggota(item) {
      this.dialogAnggota = false;
      console.log(item);
      this.currentData.nama = item.nama;
      this.currentData.user_id = item.id;
      this.currentData.cabang_asal = item.cabang_id;
      this.currentData.cabang_asal_nama = item.propinsi_nama;
      this.currentData.no_npa = item.no_npa;
    },

    approveItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogApprove = true;
    },

    closeApprove() {
      this.dialogApprove = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_cabang = res.data.list_data;

          console.log(this.getCabangPengurusId);
          if (this.getCabangPengurusId) {
            this.items_cabang.forEach((entry) => {
              if (entry.id == this.getCabangPengurusId) {
                this.search.selectedPropinsi = entry;
                this.currentData.selectedPropinsi = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showFotoBuktiBayar(foto) {
      this.fotoBuktiBayar = foto;
      this.dialogBuktiBayar = true;
    },

    async changePropinsi() {
      this.getDataFromApi();
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
