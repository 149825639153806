import http from "../http-common";
/* eslint-disable */
class ReportService {
  pelatihanList(page, itemPerPage, keyword, userId, kegiatanId) {
    return http.get(
      "/report/pelatihan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  pelatihanPretestList(page, itemPerPage, keyword, userId, kegiatanId) {
    return http.get(
      "/report/pelatihan/pretest/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  pelatihanPosttestList(page, itemPerPage, keyword, userId, kegiatanId) {
    return http.get(
      "/report/pelatihan/posttest/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&user_id=" +
        userId +
        "&kegiatan_id=" +
        kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  ujianList(page, itemPerPage, keyword, pelatihanId, userId) {
    return http.get(
      "/report/ujian/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_id=" +
        pelatihanId +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  ujianPostTestList(page, itemPerPage, keyword, pelatihanId, userId) {
    return http.get(
      "/report/ujian/posttest/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_id=" +
        pelatihanId +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiPenyelenggaraanReportList(
    page,
    itemPerPage,
    keyword,
    kegiatanId,
    userId
  ) {
    return http.get(
      "/report/evaluasi-penyelenggaraan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiPascaKegiatanReportList(
    page,
    itemPerPage,
    keyword,
    kegiatanId,
    userId
  ) {
    return http.get(
      "/report/evaluasi-pasca-kegiatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  narasumberReportList(page, itemPerPage, keyword, pelatihanId, userId) {
    return http.get(
      "/report/narasumber/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&pelatihan_id=" +
        pelatihanId +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiNarasumberReportList(
    page,
    itemPerPage,
    keyword,
    kegiatanId,
    userId,
    narasumberId
  ) {
    return http.get(
      "/report/evaluasi-narasumber/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId +
        "&narasumber_id=" +
        narasumberId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  evaluasiRTLReportList(page, itemPerPage, keyword, kegiatanId, userId) {
    return http.get(
      "/report/evaluasi-rtl/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kegiatan_id=" +
        kegiatanId +
        "&user_id=" +
        userId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  downloadPreTestReport(id) {
    return http.get(`/report/pretest/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadPostTestReport(id) {
    return http.get(`/report/posttest/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadEvaluasiPenyelenggaraanReport(id) {
    return http.get(`/report/evaluasi-penyelenggaraan/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadEvaluasiPascaKegiatanReport(id) {
    return http.get(`/report/evaluasi-pascakegiatan/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadEvaluasiFasilitatorReport(id) {
    return http.get(`/report/evaluasi-fasilitator/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadEvaluasiRtlReport(id, userId) {
    return http.get(`/report/evaluasi-rtl/download/${id}/user/${userId}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadDataPesertaReport(id) {
    return http.get(`/report/data-peserta/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadKwitansiReport(id, userId) {
    return http.get(`/report/kwitansi/download/${id}/user/${userId}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadPendapatanReport(id) {
    return http.get(`/report/pendapatan/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadBuktiBayarReport(id, userId) {
    return http.get(`/report/buktibayar/download/${id}/user/${userId}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadAbsensiReport(id) {
    return http.get(`/report/absensi/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadAnggotaReport(search, cabangId, noNpa) {
    return http.get(
      "/report/anggota/download?keyword=" +
        search +
        "&cabang_id=" +
        cabangId +
        "&no_npa=" +
        noNpa,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new ReportService();
