import http from "../http-common";
/* eslint-disable */
class EPosterPibService {
  eposterList(page, itemPerPage, keyword, status, kategori, item) {
    return http.get(
      "/user/list-eposter?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&status=" +
        status +
        "&kategori=" +
        kategori +
        "&item=" +
        item,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKategoriItem(id) {
    return http.get(`/pib-invoice/kategori-item/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getInvoice(id) {
    return http.get(`/invoice/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getMasterIuran(cabangId, tahun) {
    return http.get(
      "/iuran-nominal?cabang_id=" + cabangId + "&tahun=" + tahun,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data, photo) {
    var bodyFormData = new FormData();
    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.tahun) bodyFormData.append("tahun", data.tahun);
    if (data.nominal_cabang)
      bodyFormData.append("nominal_cabang", data.nominal_cabang);
    if (data.nominal_pusat)
      bodyFormData.append("nominal_pusat", data.nominal_pusat);
    if (data.nominal) bodyFormData.append("nominal", data.nominal);
    bodyFormData.append("langsung_setujui", data.langsung_setujui);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("kategori", data.selectedKategori.id);
    }

    if (data.selectedCabang && data.selectedCabang != undefined) {
      bodyFormData.append("cabang_id", data.selectedCabang.id);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/invoice", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.tahun) bodyFormData.append("tahun", data.tahun);
    if (data.nominal_cabang)
      bodyFormData.append("nominal_cabang", data.nominal_cabang);
    if (data.nominal_pusat)
      bodyFormData.append("nominal_pusat", data.nominal_pusat);
    if (data.nominal) bodyFormData.append("nominal", data.nominal);
    bodyFormData.append("langsung_setujui", data.langsung_setujui);

    if (data.selectedKategori && data.selectedKategori != undefined) {
      bodyFormData.append("kategori", data.selectedKategori.id);
    }

    if (data.selectedCabang && data.selectedCabang != undefined) {
      bodyFormData.append("cabang_id", data.selectedCabang.id);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/invoice/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/invoice/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  approve(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/invoice/approveiuran/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  reject(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/invoice/rejectiuran/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new EPosterPibService();
