<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Anggota</v-tab>
          <v-tab>Pekerjaan</v-tab>
          <v-tab>Pendidikan</v-tab>
          <v-tab>Seminat</v-tab>
          <v-tab>History Mutasi</v-tab>
          <v-tab>Event Diikuti</v-tab>
          <v-tab>Borang/P2KB</v-tab>
          <v-tab>Status Serkom</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title>
                <div>Informasi Anggota</div>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                >
                  Edit Data Anggota
                </v-btn>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="resetPassword"
                  color="blue"
                  dark
                >
                  Reset Password
                </v-btn>
              </v-card-title>

              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="userData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="userData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12">
                        <v-combobox
                          label="Cabang"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedCabang"
                          :items="items_propinsi"
                          item-text="name"
                          :rules="userData.cabangRules"
                          :readonly="isEditable == 0"
                          :disabled="getCabangPengurusId != null"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-if="userData.is_verified == 2"
                          label="No NPA"
                          outlined
                          dense
                          v-model="userData.no_npa"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-combobox
                          label="Jenis Anggota"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedJenisAnggota"
                          :items="items_jenis_anggota"
                          item-text="value"
                          :rules="userData.jenisAnggotaRules"
                          :readonly="isEditable == 0"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Nama & Gelar"
                          outlined
                          dense
                          v-model="userData.nama"
                          :rules="userData.namaRules"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="NPA IDI"
                          outlined
                          dense
                          v-model="userData.npa_idi"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="No Telepon"
                          outlined
                          dense
                          v-model="userData.phone"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Email"
                          outlined
                          dense
                          v-model="userData.email"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="STR"
                          outlined
                          dense
                          v-model="userData.str"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Masa Berlaku STR"
                          v-model="userData.masa_berlaku"
                          type="date"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Personal Information</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Jenis Kelamin"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedJenisKelamin"
                          :items="items_jenis_kelamin"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Status Pegawai"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedStatusPegawai"
                          :items="items_status_pegawai"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Nama Golongan"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedGolonganPegawai"
                          :items="items_golongan_pegawai"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Agama"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedAgama"
                          :items="items_agama"
                          item-text="value"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="No KTP"
                          outlined
                          dense
                          v-model="userData.no_ktp"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Tempat Lahir"
                          outlined
                          dense
                          v-model="userData.tempat_lahir"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Tanggal Lahir"
                          v-model="userData.tanggal_lahir"
                          type="date"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Propinsi"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedPropinsi"
                          :items="items_propinsi"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @change="changePropinsi"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Kabupaten/Kota"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedKabupaten"
                          :items="items_kabupaten"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @click="getListKabupaten"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Kecamatan"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedKecamatan"
                          :items="items_kecamatan"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @click="getListKecamatan"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          label="Kelurahan"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedKelurahan"
                          :items="items_kelurahan"
                          item-text="name"
                          :readonly="isEditable == 0"
                          @click="getListKelurahan"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Alamat Lengkap"
                          outlined
                          dense
                          v-model="userData.address"
                          :readonly="isEditable == 0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6"> </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Universitas Dokter Umum"
                          outlined
                          dense
                          v-model="userData.perguruan_tinggi"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="userData.selectedJenisAnggota"
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          label="Universitas Spesialis Anestesiologi"
                          outlined
                          dense
                          v-model="userData.perguruan_tinggi_spesialis"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          label="Tahun Lulus Dokter Umum"
                          outlined
                          dense
                          v-model="userData.tahun_lulus"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="
                          userData.selectedJenisAnggota &&
                          userData.selectedJenisAnggota.code == 2
                        "
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          label="Tahun Masuk Spesialis Anestesiologi"
                          outlined
                          dense
                          v-model="userData.tahun_masuk_spesialis"
                          :rules="userData.tahunMasukSpesialisRules"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="
                          userData.selectedJenisAnggota &&
                          userData.selectedJenisAnggota.code != 2
                        "
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          label="Tahun Lulus Spesialis Anestesiologi"
                          outlined
                          dense
                          v-model="userData.tahun_lulus_spesialis"
                          :rules="userData.tahunLulusSpesialisRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKeyKtp"
                          :src="userData.FotoUrlKtp"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImageKtp"
                      accept="image/*"
                      v-model="userData.file_foto_ktp"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto KTP"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKeyStr"
                          :src="userData.FotoUrlStr"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImageStr"
                      accept="image/*"
                      v-model="userData.file_foto_str"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto STR"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKeyIjazah"
                          :src="userData.FotoUrlIjazah"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImageIjazah"
                      accept="image/*"
                      v-model="userData.file_foto_ijazah"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto Ijazah Spesialis Anestesiologi"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKeyStm"
                          :src="userData.FotoUrlStm"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImageStm"
                      accept="image/*"
                      v-model="userData.file_foto_stm"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto Surat Tanda Mahasiswa"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-card class="pa-2"
                      ><a :href="userData.fileUrlIjazah" target="_blank"
                        >Link File PDF Ijazah</a
                      ></v-card
                    >
                    <v-file-input
                      @change="loadImageIjazah"
                      accept=".pdf"
                      v-model="userData.file_pdf_ijazah"
                      prepend-icon="mdi-image"
                      show-size
                      label="File PDF Ijazah Spesialis Anestesiologi"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                </v-row>
                <v-row class="pb-4">
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <PekerjaanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PendidikanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <PeminatanComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <MutasiComponent :id="id" />
          </v-tab-item>
          <v-tab-item>
            <EventDiikutiComponent :id="id" />
          </v-tab-item>
        </v-tabs>
      </v-card>

      <v-dialog v-model="dialogResetPassword" max-width="600px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan mereset password anggota ini?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeReset">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="resetItemConfirm"
              >Ya</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import NakesService from "../services/NakesService";
import FaskesService from "../services/FaskesService";
import MasterService from "../services/MasterService";
import * as moment from "moment";

import PekerjaanComponent from "./user/Pekerjaan.vue";
import PendidikanComponent from "./user/Pendidikan.vue";
import PeminatanComponent from "./user/Peminatan.vue";
import EventDiikutiComponent from "./user/EventDiikuti.vue";
import MutasiComponent from "./user/MutasiAnggota.vue";
import store from "../store/index";

export default {
  name: "NakesEdit",
  components: {
    PekerjaanComponent,
    PendidikanComponent,
    PeminatanComponent,
    EventDiikutiComponent,
    MutasiComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    dialogResetPassword: false,
    komunitas_key: 0,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Anggota",
        disabled: false,
        exact: true,
        to: { name: "Nakes" },
      },
      { text: "Edit Anggota", disabled: true },
    ],
    valid: true,
    userData: {
      is_verified: "",
      FotoUrl: "",
      FotoUrlKtp: "",
      FotoUrlStr: "",
      FotoUrlIjazah: "",
      fileUrlIjazah: "",
      FotoUrlStm: "",
      file_foto: null,
      file_foto_ktp: null,
      file_foto_str: null,
      file_foto_ijazah: null,
      file_pdf_ijazah: null,
      file_foto_stm: null,
      gelar_depan: "",
      nama: "",
      gelar_belakang: "",
      email: "",
      phone: "",
      profesi_id: "",
      str: "",
      no_ktp: "",
      no_npa: "",
      npa_idi: "",
      tempat_lahir: "",
      selectedProfesi: "",
      selectedJenisKelamin: "",
      selectedStatusPegawai: "",
      selectedGolonganPegawai: "",
      selectedAgama: "",
      selectedCommunity: "",
      selectedPropinsi: "",
      selectedKabupaten: "",
      selectedKecamatan: "",
      selectedKelurahan: "",
      selectedCabang: "",
      selectedJenisAnggota: "",
      no_anggota: "",
      tanggal_lahir: "",
      address: "",
      masa_berlaku: "",
      perguruan_tinggi: "",
      perguruan_tinggi_spesialis: "",
      tahun_lulus: "",
      tahun_lulus_spesialis: "",
      tahun_masuk_spesialis: "",
      cabangRules: [(v) => !!v || "Cabang harus diisi"],
      jenisAnggotaRules: [(v) => !!v || "Jenis Anggota harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      phoneRules: [(v) => !!v || "No Telepon harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      noAnggotaRules: [(v) => !!v || "No Anggota harus diisi"],
      perguruanTinggiRules: [
        (v) => !!v || "Universitas Dokter Umum harus diisi",
      ],
      perguruanTinggiSpesialisRules: [
        (v) => !!v || "Universitas Spesialis Anestesiologi harus diisi",
      ],
      tahunLulusRules: [(v) => !!v || "Tahun Lulus Dokter Umum harus diisi"],
      tahunMasukSpesialisRules: [
        (v) => !!v || "Tahun Masuk Spesialis Anestesiologi harus diisi",
        (v) => (v && v.length == 4) || "Tahun harus 4 digit",
      ],
      tahunLulusSpesialisRules: [
        (v) => !!v || "Tahun Lulus Spesialis Anestesiologi harus diisi",
        (v) => (v && v.length == 4) || "Tahun harus 4 digit",
      ],
    },
    items_profesi: [],
    items_jenis_kelamin: [],
    items_status_pegawai: [],
    items_golongan_pegawai: [],
    items_agama: [],
    items_community: [],
    items_propinsi: [],
    items_kabupaten: [],
    items_kecamatan: [],
    items_kelurahan: [],
    items_jenis_anggota: [],
    result: {},
    fotoKey: 0,
    fotoKeyKtp: 0,
    fotoKeyStr: 0,
    fotoKeyIjazah: 0,
    fotoKeyStm: 0,
    currentFaskes: {
      id: "",
      nama: "",
    },
    dialogFaskes: false,
    searchNakes: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Kategori", value: "category_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
  }),
  mounted: async function () {
    this.getJenisAnggota();
    this.getListPropinsi();
    this.getListKabupaten();
    this.getListProfesi();
    this.getListJenisKelamin();
    this.getListStatusPegawai();
    this.getListGolonganPegawai();
    this.getListAgama();
    this.getListCommunityAll();
    this.getUser();
  },
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getRolesId() {
      return store.getters.getUser.roles_id;
    },
    getCabangPengurusId() {
      return store.getters.getUser.cabang_pengurus_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUserData() {
      return store.getters.getUser;
    },
  },
  watch: {
    options: {
      handler() {
        this.getNakesFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async getJenisAnggota() {
      MasterService.jenisAnggotaList()
        .then((res) => {
          this.items_jenis_anggota = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pilihFaskes: function (item) {
      console.log(item);
      this.currentFaskes.id = item.id;
      this.currentFaskes.nama = item.nama;
      this.userData.faskes_id = item.id;

      this.dialogFaskes = false;
    },

    clearCurrentFakes: function () {
      this.currentFaskes.id = "";
      this.currentFaskes.nama = "";
      this.userData.faskes_id = "";

      console.log(this.currentFaskes.id);
    },

    getNakesFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      FaskesService.faskesList(page, itemsPerPage, this.searchNakes)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    openDialogFaskes: function () {
      this.dialogFaskes = !this.dialogFaskes;

      if (this.dialogFaskes) {
        this.getNakesFromApi();
        this.options.page = 1;
      }
    },

    changeEditable: function () {
      this.isEditable = 1;
    },

    resetPassword: function () {
      this.dialogResetPassword = true;
    },

    closeReset: function () {
      this.dialogResetPassword = false;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    loadImageKtp: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrlKtp = e.target.result;
          this.fotoKeyKtp++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrlKtp = "";
        this.userData.file_foto_ktp = null;
      }
    },

    loadImageStr: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrlStr = e.target.result;
          this.fotoKeyStr++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrlStr = "";
        this.userData.file_foto_str = null;
      }
    },

    loadImageIjazah: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrlIjazah = e.target.result;
          this.fotoKeyIjazah++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrlIjazah = "";
        this.userData.file_foto_ijazah = null;
      }
    },

    loadImageStm: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrlStm = e.target.result;
          this.fotoKeyStm++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrlStm = "";
        this.userData.file_foto_stm = null;
      }
    },

    getUser() {
      NakesService.getUser(this.id)
        .then((res) => {
          console.log(res.data.user);
          this.userData.gelar_depan = res.data.user.gelar_depan;
          this.userData.nama = res.data.user.nama;
          this.userData.gelar_belakang = res.data.user.gelar_belakang;
          this.userData.no_anggota = res.data.user.no_anggota;
          this.userData.phone = res.data.user.phone;
          this.userData.email = res.data.user.email;
          this.userData.str = res.data.user.str;
          this.userData.no_ktp = res.data.user.no_ktp;
          this.userData.tempat_lahir = res.data.user.tempat_lahir;
          this.userData.address = res.data.user.address;
          this.userData.no_npa = res.data.user.no_npa;
          this.userData.npa_idi = res.data.user.npa_idi;
          this.userData.perguruan_tinggi = res.data.user.perguruan_tinggi;
          this.userData.is_verified = res.data.user.is_verified;
          this.userData.perguruan_tinggi_spesialis =
            res.data.user.perguruan_tinggi_spesialis;

          this.userData.tahun_lulus = res.data.user.tahun_lulus;
          this.userData.tahun_lulus_spesialis =
            res.data.user.tahun_lulus_spesialis;
          this.userData.tahun_masuk_spesialis =
            res.data.user.tahun_masuk_spesialis;

          if (res.data.user.tanggal_lahir) {
            this.userData.tanggal_lahir = moment(
              res.data.user.tanggal_lahir
            ).format("YYYY-MM-DD");
          }

          if (res.data.user.masa_berlaku) {
            this.userData.masa_berlaku = moment(
              res.data.user.masa_berlaku
            ).format("YYYY-MM-DD");
          }

          if (res.data.user.profesi_id) {
            this.items_profesi.forEach((entry) => {
              if (entry.code == res.data.user.profesi_id) {
                this.userData.selectedProfesi = entry;
              }
            });
          }

          if (res.data.user.jenis_kelamin_id) {
            this.items_jenis_kelamin.forEach((entry) => {
              if (entry.code == res.data.user.jenis_kelamin_id) {
                this.userData.selectedJenisKelamin = entry;
              }
            });
          }

          if (res.data.user.status_pekerjaan_id) {
            this.items_status_pegawai.forEach((entry) => {
              if (entry.code == res.data.user.status_pekerjaan_id) {
                this.userData.selectedStatusPegawai = entry;
              }
            });
          }

          if (res.data.user.golongan_pekerjaan_id) {
            this.items_golongan_pegawai.forEach((entry) => {
              if (entry.code == res.data.user.golongan_pekerjaan_id) {
                this.userData.selectedGolonganPegawai = entry;
              }
            });
          }

          if (res.data.user.agama_id) {
            this.items_agama.forEach((entry) => {
              if (entry.code == res.data.user.agama_id) {
                this.userData.selectedAgama = entry;
              }
            });
          }

          if (res.data.user.community_id) {
            this.items_community.forEach((entry) => {
              if (entry.id == res.data.user.community_id) {
                this.userData.selectedCommunity = entry;
              }
            });
          }

          if (res.data.user.propinsi_id) {
            this.items_propinsi.forEach((entry) => {
              if (entry.id == res.data.user.propinsi_id) {
                this.userData.selectedPropinsi = entry;
              }
            });

            this.getListKabupatenAll(
              res.data.user.propinsi_id,
              res.data.user.kabupaten_id
            );
          }

          this.items_propinsi.forEach((entry) => {
            if (entry.id == res.data.user.cabang_id) {
              this.userData.selectedCabang = entry;
            }
          });

          if (res.data.user.kecamatan_id) {
            this.getListKecamatanAll(
              res.data.user.kabupaten_id,
              res.data.user.kecamatan_id
            );
          }

          if (res.data.user.kelurahan_id) {
            this.getListKelurahanAll(
              res.data.user.kecamatan_id,
              res.data.user.kelurahan_id
            );
          }

          this.items_jenis_anggota.forEach((entry) => {
            if (entry.code == res.data.user.jenis_anggota) {
              this.userData.selectedJenisAnggota = entry;
            }
          });

          this.userData.FotoUrl = res.data.user.photo;
          this.userData.FotoUrlKtp = res.data.user.foto_ktp;
          this.userData.FotoUrlStr = res.data.user.foto_str;
          this.userData.FotoUrlIjazah = res.data.user.foto_ijazah;
          this.userData.FotoUrlStm = res.data.user.foto_stm;
          this.userData.fileUrlIjazah = res.data.user.file_ijazah;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListProfesi() {
      NakesService.profesiList()
        .then((res) => {
          this.items_profesi = res.data.list_data;

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListJenisKelamin() {
      NakesService.jenisKelaminList()
        .then((res) => {
          this.items_jenis_kelamin = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListStatusPegawai() {
      MasterService.statusPekerjaanList()
        .then((res) => {
          this.items_status_pegawai = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListGolonganPegawai() {
      MasterService.golonganPekerjaanList()
        .then((res) => {
          this.items_golongan_pegawai = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListAgama() {
      MasterService.agamaList()
        .then((res) => {
          this.items_agama = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListPropinsi() {
      NakesService.propinsiList()
        .then((res) => {
          this.items_propinsi = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async changePropinsi() {
      this.userData.selectedKabupaten = null;
    },

    async getListKabupaten() {
      NakesService.kabupatenList(this.userData.selectedPropinsi.id)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKabupatenAll(propinsiId, kabupatenId) {
      NakesService.kabupatenList(propinsiId)
        .then((res) => {
          this.items_kabupaten = res.data.list_data;
          if (kabupatenId) {
            this.items_kabupaten.forEach((entry) => {
              if (entry.id == kabupatenId) {
                this.userData.selectedKabupaten = entry;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatanAll(kabupatenId, kecamatanId) {
      NakesService.kecamatanList(kabupatenId)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;

          this.items_kecamatan.forEach((entry) => {
            if (entry.id == kecamatanId) {
              this.userData.selectedKecamatan = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahanAll(kecamatanId, kelurahanId) {
      NakesService.kelurahanList(kecamatanId)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;

          this.items_kelurahan.forEach((entry) => {
            if (entry.id == kelurahanId) {
              this.userData.selectedKelurahan = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKecamatan() {
      NakesService.kecamatanList(this.userData.selectedKabupaten.id)
        .then((res) => {
          this.items_kecamatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListKelurahan() {
      NakesService.kelurahanList(this.userData.selectedKecamatan.id)
        .then((res) => {
          this.items_kelurahan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListCommunity() {
      console.log("get list community");
      NakesService.communityList(this.userData.selectedProfesi.code)
        .then((res) => {
          this.items_community = res.data.list_data;
          console.log(this.items_community);
          this.$nextTick(() => {
            console.log(this.value);
            this.userData.komunitas_key++;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListCommunityAll() {
      NakesService.communityListAll()
        .then((res) => {
          this.items_community = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      NakesService.update(
        this.id,
        this.userData,
        this.userData.file_foto,
        this.userData.file_foto_ktp,
        this.userData.file_foto_ijazah,
        this.userData.file_foto_str,
        this.userData.file_foto_stm,
        this.userData.file_pdf_ijazah
      )
        .then((res) => {
          console.log(res);
          this.isEditable = 0;
          this.$toast.success("Update anggota berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getUser();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update anggota gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    resetItemConfirm: async function () {
      NakesService.resetPassword(
        this.id,
        this.userData,
        this.userData.file_foto,
        this.userData.file_foto_ktp,
        this.userData.file_foto_ijazah,
        this.userData.file_foto_str,
        this.userData.file_foto_stm
      )
        .then((res) => {
          this.dialogResetPassword = false;
          console.log(res);
          this.isEditable = 0;
          this.$toast.success("Reset Password berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getUser();
        })
        .catch((err) => {
          this.dialogResetPassword = false;
          console.log(err);
          this.$toast.error("Reset Password gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
