import http from "../http-common";
/* eslint-disable */
class InvoiceItemPibService {
  invoiceItemList(page, itemPerPage, keyword, invoiceId) {
    return http.get(
      "/pib-invoice-item/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&invoice_id=" +
        invoiceId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new InvoiceItemPibService();
