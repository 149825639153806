<template>
  <div>
    <v-card>
      <v-card-title>
        Pre Test <br />

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Judul"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn color="primary" dark class="mb-2" @click="openTambah">
          Tambah
        </v-btn>
        <v-btn color="primary" dark class="mb-2 ml-4" @click="openCopyTest">
          Copy Test
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Pertanyaan</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Pertanyaan"
                        required
                        v-model="currentData.pertanyaan"
                        :rules="currentData.pertanyaanRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Urutan"
                        required
                        v-model="currentData.urutan"
                        :rules="currentData.urutanRules"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Skor"
                        required
                        v-model="currentData.score"
                        :rules="currentData.scoreRules"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCopyTest" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Copy Soal Test</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="formcopytest"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-combobox
                        label="Pelatihan"
                        clearable
                        dense
                        outlined
                        v-model="currentCopyTest.selectedPelatihan"
                        :items="items_pelatihan"
                        :rules="currentCopyTest.pelatihanRules"
                      >
                        <template slot="selection" slot-scope="data">
                          {{ data.item.title }} - (kategori:
                          {{ data.item.kategori_pelatihan }} ) - (pelatihan:
                          {{ data.item.pelatihan }}) - (kegiatan:
                          {{ data.item.kegiatan_title }} )
                        </template>
                        <template slot="item" slot-scope="data">
                          {{ data.item.title }} - (kategori:
                          {{ data.item.kategori_pelatihan }} ) - (pelatihan:
                          {{ data.item.pelatihan }}) - (kegiatan:
                          {{ data.item.kegiatan_title }} )
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCopyTest = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doCopyTest">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        v-model="selected"
        :single-select="true"
        show-select
      >
        <template v-slot:[`item.kategori`]="{ item }">
          <v-chip :color="getColor(item.kategori)" dark>
            {{ getKategoriDescription(item.kategori) }}
          </v-chip>
        </template>
        <template v-slot:[`item.video`]="{ item }">
          <a v-if="item.video" :href="item.video" target="_blank">
            Link Video
          </a>
          <span v-else></span>
        </template>
        <template v-slot:[`item.file_pdf`]="{ item }">
          <a
            v-if="item.file_pdf_original"
            :href="item.file_pdf"
            target="_blank"
          >
            Link File
          </a>
          <span v-else></span>
        </template>
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-4">
      <PelatihanUjianPilihanComponent
        v-if="selected != undefined && selected.length > 0"
        :id="selected[0].id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>

    <!-- Modal -->
  </div>
</template>

<script>
import UjianService from "../../services/UjianService";
import CommonService from "../../services/CommonService";

import PelatihanUjianPilihanComponent from "./PelatihanUjianPilihan.vue";

export default {
  props: {
    id: Number,
  },
  components: {
    PelatihanUjianPilihanComponent,
  },
  data: () => ({
    childKey: 0,
    selected: [],
    isEditable: 0,
    valid: true,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Pertanyaan", value: "pertanyaan", sortable: false },
      { text: "Urutan", value: "urutan", sortable: false },
      { text: "Skor", value: "score", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
      page: 1,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    dialogCopyTest: false,

    currentData: {
      pelatihan_detail_id: "",
      id: null,
      pertanyaan: "",
      urutan: "",
      pertanyaanRules: [(v) => !!v || "Pertanyaan harus diisi"],
      urutanRules: [(v) => !!v || "Urutan harus diisi"],
      scoreRules: [(v) => !!v || "Skor harus diisi"],
    },

    currentCopyTest: {
      selectedPelatihan: null,
      pelatihanRules: [(v) => !!v || "Test Pelatihan harus diisi"],
    },

    items_pelatihan: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      UjianService.ujianList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async ujianListCopy() {
      UjianService.ujianListCopy()
        .then((res) => {
          this.items_pelatihan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openTambah() {
      this.currentData.id = null;
      this.dialog = true;
      this.isEditable = 1;

      this.currentData.pertanyaan = "";
      this.currentData.urutan = "";
      this.currentData.score = "";
    },

    openCopyTest() {
      this.currentData.id = null;
      this.dialogCopyTest = true;
      this.isEditable = 1;

      this.currentData.pertanyaan = "";
      this.currentData.urutan = "";

      this.ujianListCopy();
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      console.log(this.editedItem);
      this.currentData.pertanyaan = this.editedItem.pertanyaan;
      this.currentData.urutan = this.editedItem.urutan;
      this.currentData.score = this.editedItem.score;
      this.currentData.id = this.editedItem.id;

      this.isEditable = 1;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.currentData.id == null) {
        this.currentData.pelatihan_detail_id = this.id;
        UjianService.createPreTest(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah pertanyaan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast(
              "Tambah pertanyaan gagal. Coba lagi"
            );
          });
      } else {
        this.currentData.pelatihan_detail_id = this.id;

        UjianService.update(this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);

            CommonService.showSuccessToast("Update pertanyaan berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);

            CommonService.showSuccessToast(
              "Update pertanyaan gagal. Coba lagi"
            );
          });
      }
    },

    doCopyTest: async function () {
      if (!this.$refs.formcopytest.validate()) {
        return;
      }

      this.currentCopyTest.pelatihan_detail_id = this.id;
      this.currentCopyTest.pelatihan_detail_id_copy =
        this.currentCopyTest.selectedPelatihan.id;
      UjianService.copyPreTest(this.currentCopyTest)
        .then((res) => {
          console.log(res);
          this.dialogCopyTest = false;

          CommonService.showSuccessToast("Copy soal test berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showSuccessToast("Copy soal test gagal. Coba lagi");
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      UjianService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pertanyaan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Hapus pertanyaan gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
