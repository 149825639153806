import http from "../http-common";
/* eslint-disable */
class WorkshopService {
  workshopList(page, itemPerPage, keyword, id) {
    return http.get(
      "/pib/workshop/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.kuota) bodyFormData.append("kuota", data.kuota);
    if (data.harga) bodyFormData.append("harga", data.harga);
    if (data.early_bird_tanggal_awal)
      bodyFormData.append(
        "early_bird_tanggal_awal",
        data.early_bird_tanggal_awal
      );
    if (data.early_bird_tanggal_akhir)
      bodyFormData.append(
        "early_bird_tanggal_akhir",
        data.early_bird_tanggal_akhir
      );
    if (data.harga_early_bird)
      bodyFormData.append("harga_early_bird", data.harga_early_bird);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.post("/pib/workshop", bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    if (data.pib_id) bodyFormData.append("pib_id", data.pib_id);
    if (data.nama) bodyFormData.append("nama", data.nama);
    if (data.deskripsi) bodyFormData.append("deskripsi", data.deskripsi);
    if (data.lokasi) bodyFormData.append("lokasi", data.lokasi);
    if (data.tanggal_awal)
      bodyFormData.append("tanggal_awal", data.tanggal_awal);
    if (data.tanggal_akhir)
      bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    if (data.kuota) bodyFormData.append("kuota", data.kuota);
    if (data.harga) bodyFormData.append("harga", data.harga);
    if (data.early_bird_tanggal_awal)
      bodyFormData.append(
        "early_bird_tanggal_awal",
        data.early_bird_tanggal_awal
      );
    if (data.early_bird_tanggal_akhir)
      bodyFormData.append(
        "early_bird_tanggal_akhir",
        data.early_bird_tanggal_akhir
      );
    if (data.harga_early_bird)
      bodyFormData.append("harga_early_bird", data.harga_early_bird);

    if (data.file_foto != null) bodyFormData.append("photo", data.file_foto);

    return http.put(`/pib/workshop/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/pib/workshop/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new WorkshopService();
